import React, { Component } from 'react';
import ReactModal from 'react-modal';
import IconButton from '../../../../../components/common/buttons/icon-button/IconButton';
import styles from '../../../../../styles/ModalWindow.module.scss';
import { IUnapproveEntryModalProps } from "./IUnapproveEntryModalProps";
import { IUnapproveEntryModalState } from "./IUnapproveEntryModalState";

class UnapproveEntryModal extends Component<IUnapproveEntryModalProps, IUnapproveEntryModalState> {

  private static dialogIndex = 100;

  constructor(props: IUnapproveEntryModalProps) {
    super(props);

    this.state = {
      dialogId: 'unapprove_entry_dialog_' + (UnapproveEntryModal.dialogIndex++)
    };

    this.onConfirmClicked = this.onConfirmClicked.bind(this);
  }

  render() {
    return (
      <ReactModal isOpen={this.props.showDialog}
                  id={this.state.dialogId}
                  shouldCloseOnOverlayClick={false}
                  shouldCloseOnEsc={false}
                  className={`${styles.content} shadow p-4`}
                  overlayClassName={`${styles.overlay}`}
                  appElement={document.getElementById('root') as HTMLElement}
      >

        <h2>Unapprove entry</h2>

        <p>
          Are you sure you want to un-approve the entry?
          Voting is over for this contest. It will not be possible to approve the entry back again since voting is over.
        </p>

        <div className="d-flex justify-content-end">
          <IconButton disabled={this.props.isLoading} styles={{marginRight: '1em'}}
                      onClick={this.onConfirmClicked} variant="outline-primary" title="Confirm"/>
          <IconButton onClick={this.props.onCancel} variant="outline-secondary" title="Cancel"/>
        </div>
      </ReactModal>
    );
  }

  onConfirmClicked() {
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
  }
}

export default UnapproveEntryModal;