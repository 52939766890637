import React, { Component } from 'react';
import ReactModal from 'react-modal';
import IconButton from "../../../components/common/buttons/icon-button/IconButton";
import styles from '../../../styles/ModalWindow.module.scss';
import { IDeleteContestModalProps } from "./IDeleteContestModalProps";
import { IDeleteContestModalState } from "./IDeleteContestModalState";

class DeleteContestModal extends Component<IDeleteContestModalProps, IDeleteContestModalState> {

  private static dialogIndex = 100;

  constructor(props: IDeleteContestModalProps) {
    super(props);

    this.state = {
      dialogId: 'delete_contest_dialog_' + (DeleteContestModal.dialogIndex++)
    };
  }

  render() {
    return (
      <ReactModal isOpen={this.props.showDialog}
                  id={this.state.dialogId}
                  shouldCloseOnOverlayClick={false}
                  shouldCloseOnEsc={false}
                  className={`${styles.content} shadow p-4`}
                  overlayClassName={`${styles.overlay}`}
                  appElement={document.getElementById('root') as HTMLElement}
      >

        <h2>Delete contest</h2>

        <p>Are you sure you want to delete <b className={"text-break"}> {this.props.contestNameToDelete}</b>?</p>

        <div className="d-flex justify-content-end">
          <IconButton onClick={this.onConfirmClicked.bind(this)} variant="outline-danger" title="Delete"
                      styles={{marginRight: '1em'}} disabled={this.props.isLoading}/>
          <IconButton onClick={this.props.onCancel.bind(this)} variant="outline-secondary" title="Cancel"/>
        </div>
      </ReactModal>
    );
  }

  onConfirmClicked() {
    if (this.props.onConfirm) {
      this.props.onConfirm(this.props.contestIdToDelete ?? 0);
    }
  }
}

export default DeleteContestModal;