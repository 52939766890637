import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from 'react';
import ImageComponent from "../../../../../components/common/image-component/ImageComponent";
import { VerticalSpaceSize } from "../../../../../components/common/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../../../components/common/vertical-space/VerticalSpace";
import { CUSTOM_FIELD_CODES, KIWANIS_GOLD_COLOR, MOMENT_DATE_FORMAT } from "../../../../../constants/Constants";
import { IEntriesPdfDocumentProps } from "./IEntriesPdfDocumentProps";

// Create styles
const styles: any = {
  body: {
    width: '210mm',
    height: '297mm',
    paddingLeft: 50,
    paddingRight: 50,
  },
  title: {
    paddingTop: 35,
    marginBottom: 15,
    fontSize: '2em',
    textAlign: 'center',
    wordBreak: 'break-word',
    fontFamily: 'Arial',
    fontWeight: 'bold'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  image: {
    width: '35%',
    marginRight: '20px'
  },
  column2: {
    width: '65%',
    textAlign: 'left',
  },
  subtitle: {
    fontSize: '1.3em',
    wordBreak: 'break-word',
    fontFamily: 'Arial',
    fontWeight: 'bold'
  },
  field: {
    marginBottom: '0.5em'
  },
  text: {
    fontSize: 14,
    textAlign: 'justify',
    wordBreak: 'break-word',
  },
  label: {
    fontSize: 14,
    fontFamily: 'Arial',
    fontWeight: 'bold',
    wordBreak: 'break-word',
  },
  value: {
    fontSize: 14,
    color: 'gray',
    whiteSpace: "pre-line",
    wordBreak: 'break-word',
  },
  description: {
    fontStyle: 'normal',
    fontSize: 14,
  },
};

// Create PDF Document Component
const EntriesPdfDocument = (props: IEntriesPdfDocumentProps) => (
  <>
    <div style={styles.body} className="d-none d-print-block">
      {
        props.entries && props.entries.map((entry, index) => (
          <div key={index} style={{pageBreakAfter: 'always'}}>
            <h1 style={styles.title}>{entry.contestName}</h1>

            <div style={styles.row}>
              <div style={styles.image}>
                <ImageComponent src={entry.defaultPhotoUrl}/>
              </div>

              <div style={styles.column2}>
                <div style={styles.field}>
                  <div style={styles.subtitle}>{entry.title}</div>
                  <div style={styles.value}>{entry.description}</div>
                </div>

                <div style={styles.field}>
                  <div style={styles.label}>Registration date</div>
                  <div style={styles.value}>
                    {entry.submittedOn ? moment(entry.submittedOn).format(MOMENT_DATE_FORMAT) : null}
                  </div>
                </div>

                <div style={styles.field}>
                  <div style={styles.label}>Submitter</div>
                  <div style={styles.value}>{entry.submitterFullName}</div>
                </div>

                <div style={styles.field}>
                  <div style={styles.label}>Number of votes</div>
                  <div style={styles.value}>
                    {
                      entry.isWinner
                      ? <span><FontAwesomeIcon icon={faTrophy} color={KIWANIS_GOLD_COLOR}/>&nbsp;</span>
                      : null
                    }
                    {entry.votesCount}
                  </div>
                </div>

                <div style={styles.field}>
                  <div style={styles.label}>Number of views</div>
                  <div style={styles.value}>{entry.viewsCount}</div>
                </div>

                <div style={styles.field}>
                  <div style={styles.label}>{entry.isApproved ? null : <span>Not Approved</span>}</div>
                </div>
              </div>
            </div>
            <VerticalSpace size={VerticalSpaceSize.small}/>

            <h3 style={styles.subtitle}>Fields</h3>
            {
              entry.fields && entry.fields.map((field, j) => {
                switch (field.fieldTypeCode) {
                  case CUSTOM_FIELD_CODES.Text:
                    return (
                      <div key={j} className="text-break" style={styles.field}>
                        <div style={styles.label}>{field.name}</div>
                        <div style={styles.value}>{field.stringValue}</div>
                        <div style={styles.description}>{field.description}</div>
                      </div>
                    );
                  case CUSTOM_FIELD_CODES.TextArea:
                    return (
                      <div key={j} className="text-break" style={styles.field}>
                        <div style={styles.label}>{field.name}</div>
                        <div style={styles.value}><span style={{whiteSpace: "pre-line"}}>{field.stringValue}</span>
                        </div>
                        <div style={styles.description}>{field.description}</div>
                      </div>
                    );
                  case CUSTOM_FIELD_CODES.RadioButton:
                    return (
                      <div key={j} className="text-break" style={styles.field}>
                        <div style={styles.label}>{field.name}</div>
                        <div style={styles.value}>{field.stringValue}</div>
                        <div style={styles.description}>{field.description}</div>
                      </div>
                    );
                  case CUSTOM_FIELD_CODES.DropDownList:
                    return (
                      <div key={j} className="text-break" style={styles.field}>
                        <div style={styles.label}>{field.name}</div>
                        <div style={styles.value}>{field.stringValue}</div>
                        <div style={styles.description}>{field.description}</div>
                      </div>
                    );
                  case CUSTOM_FIELD_CODES.Checkbox:
                    return (
                      <div key={j} className="text-break" style={styles.field}>
                        <div style={styles.label}>{field.name}</div>
                        <div style={styles.value}>{field.boolValue ? 'Yes' : 'No'}</div>
                        <div style={styles.description}>{field.description}</div>
                      </div>
                    );
                  case CUSTOM_FIELD_CODES.DatePicker:
                    return (
                      <div key={j} className="text-break" style={styles.field}>
                        <div style={styles.label}>{field.name}</div>
                        <div style={styles.value}>
                          {field.dateTimeValue ? moment(field.dateTimeValue).format(MOMENT_DATE_FORMAT) : null}
                        </div>
                        <div style={styles.description}>{field.description}</div>
                      </div>
                    );
                  default:
                    return (
                      <div className={'text-center'}>Invalid field</div>
                    );
                }
              })
            }
          </div>
        ))
      }
    </div>
  </>
);


export default EntriesPdfDocument;
