import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { Component } from "react";
import { Button, Col, Image, Row, Table } from "react-bootstrap-v5";
import Pagination from "react-responsive-pagination";
import Api from "../../../../api/Api";
import {
  IAddVotesManuallyFromVoteListRequest
} from "../../../../api/api-interfaces/entry/vote-list/IAddVotesManuallyFromVoteListRequest";
import { IDeleteVoteRequest } from "../../../../api/api-interfaces/entry/vote-list/IDeleteVoteRequest";
import { IExportVotesToExcelRequest } from "../../../../api/api-interfaces/entry/vote-list/IExportVotesToExcelRequest";
import { IGetVotesRequest } from "../../../../api/api-interfaces/entry/vote-list/IGetVotesRequest";
import withVotingAppAuthorize from "../../../../components/common/authorize/withVotingAppAuthorize";
import IconButton from "../../../../components/common/buttons/icon-button/IconButton";
import LoadingBar from "../../../../components/common/loading-bar/LoadingBar";
import SortingTableTitle from "../../../../components/common/sorting-table-title/SortingTableTitle";
import ValidationSummary from "../../../../components/common/validation-summary/ValidationSummary";
import { KIWANIS_BLUE_COLOR, MOMENT_DATE_FORMAT, VOTE_SORTING } from "../../../../constants/Constants";
import AuthHelper from "../../../../helpers/auth-helper/AuthHelper";
import ValidationErrors from "../../../../helpers/validation-helper/ValidationErrors";
import Validations from "../../../../helpers/validation-helper/Validations";
import imageStyles from "../../../../styles/ImageBigStyles.module.scss";
import AddManualVotesModal from "../add-manual-votes-modal/AddManualVotesModal";
import DeleteVoteModal from "./delete-vote-modal/DeleteVoteModal";
import { IVoteListProps } from "./IVoteListProps";
import { IVoteListState } from "./IVoteListState";
import styles from './VoteList.module.scss';
import ExportHelper from "../../../../helpers/export-helper/ExportHelper";

class VoteList extends Component<IVoteListProps, IVoteListState> {

  constructor(props: IVoteListProps) {
    super(props);

    this.state = {
      contestId: 0,
      contestName: "",

      contestEntryId: 0,
      entryTitle: "",
      entryDescription: "",
      entryDefaultImage: "",
      totalEntryVotes: 0,
      votes: [],

      sorting: VOTE_SORTING.SubmitTime,
      page: 1,
      totalPages: 1,

      isAddVotesDialogOpen: false,

      isDeleteVoteDialogOpen: false,
      voteIdToDelete: undefined,
      voteIndexToDelete: undefined,
      voteAmountToDelete: undefined,

      fileName: '',
      xlsxBase64: '',

      isLoading: true,
      validationErrors: null,
      excludeKeys: [],
    };
  }

  render() {
    let {
      contestName, contestEntryId, entryTitle, entryDescription, entryDefaultImage, totalEntryVotes, votes
    } = this.state;

    return (
      <>
        {
          this.state.validationErrors &&
          <Row>
            <Col md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}}>
              <ValidationSummary errors={this.state.validationErrors} excludeKeys={this.state.excludeKeys}/>
            </Col>
          </Row>
        }

        {
          this.state.isLoading ? <LoadingBar/> :
            <>
              <AddManualVotesModal
                isLoading={this.state.isLoading}
                totalNumberOfVotes={this.state.totalEntryVotes}
                entryId={this.state.contestEntryId}
                entryName={this.state.entryTitle}
                showDialog={this.state.isAddVotesDialogOpen}
                onConfirm={this.onConfirmAddVotesClick.bind(this)}
                onCancel={this.onCancelAddVotesClick.bind(this)}
              />

              <DeleteVoteModal
                isLoading={this.state.isLoading}
                showDialog={this.state.isDeleteVoteDialogOpen}
                voteIdToDelete={this.state.voteIdToDelete}
                voteAmountToDelete={this.state.voteAmountToDelete}
                onConfirm={this.onConfirmDeleteVoteClick.bind(this)}
                onCancel={this.onCancelDeleteVoteClick.bind(this)}
              />

              <h1 className={"text-break headline"}>{entryTitle}</h1>

              <p className='text-break'>{entryDescription}</p>

              <Row>
                <Col sm={{span: 6, offset: 3}} lg={{span: 4, offset: 0}} className='mb-3'>
                  <div className={imageStyles.vaContainerWithoutOverlay}>
                    <Image src={entryDefaultImage} alt="entry img" className={imageStyles.vaImage} fluid thumbnail/>
                  </div>
                </Col>

                <Col lg={8}>
                  <Row>
                    <Col>
                      <b>Contest</b>
                      <div className="text-secondary text-break">
                        {contestName}
                      </div>
                    </Col>
                  </Row>

                  <Row className='my-2'>
                    <Col>
                      <b>Number of votes</b>
                      <div className="text-secondary">
                        <FontAwesomeIcon icon={faHeart} color={KIWANIS_BLUE_COLOR}/> {totalEntryVotes}

                        {
                          AuthHelper.isCurrentUserAdmin() &&
                          <span className='mx-2'>
                            <IconButton iconType={'add'} buttonType={'button'} variant={'outline-primary'} size={"sm"}
                                        onClick={this.onAddVotes.bind(this)}
                            />
                          </span>
                        }
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <h2>Votes</h2>

              <Row className='mb-2'>
                <Col xxl={3} className='my-1'>
                  <Button className="btn btn-primary btn-sm" onClick={() => this.onExportToExcelClick(contestEntryId)}>
                    Export to Excel
                  </Button>
                </Col>
              </Row>

              <Table striped bordered responsive className={styles.columnWith}>
                <thead>
                <tr className='text-break'>
                  <th className='text-center' style={{minWidth: '5rem'}}>
                    <SortingTableTitle name="Amount"
                                       sorting={this.state.sorting}
                                       sortBy={VOTE_SORTING.Amount}
                                       sortByDesc={VOTE_SORTING.AmountDescending}
                                       onSortByClick={this.sortByAmountClick.bind(this)}
                    />
                  </th>
                  <th>
                    <SortingTableTitle name="Vote time"
                                       sorting={this.state.sorting}
                                       sortBy={VOTE_SORTING.SubmitTime}
                                       sortByDesc={VOTE_SORTING.SubmitTimeDescending}
                                       onSortByClick={this.sortBySubmitTimeClick.bind(this)}
                    />
                  </th>
                  <th>
                    <SortingTableTitle name="Last name"
                                       sorting={this.state.sorting}
                                       sortBy={VOTE_SORTING.SubmitterLastName}
                                       sortByDesc={VOTE_SORTING.SubmitterLastNameDescending}
                                       onSortByClick={this.sortBySubmitterLastNameClick.bind(this)}
                    />
                  </th>
                  <th>
                    <SortingTableTitle name="First name"
                                       sorting={this.state.sorting}
                                       sortBy={VOTE_SORTING.SubmitterFirstName}
                                       sortByDesc={VOTE_SORTING.SubmitterFirstNameDescending}
                                       onSortByClick={this.sortBySubmitterFirstNameClick.bind(this)}
                    />
                  </th>
                  <th>
                    <SortingTableTitle name="Email"
                                       sorting={this.state.sorting}
                                       sortBy={VOTE_SORTING.SubmitterEmail}
                                       sortByDesc={VOTE_SORTING.SubmitterEmailDescending}
                                       onSortByClick={this.sortBySubmitterEmailClick.bind(this)}
                    />
                  </th>
                  {
                    AuthHelper.isCurrentUserAdmin() &&
                    <th className={'text-center'} style={{minWidth: '3rem'}}/>
                  }
                </tr>
                </thead>
                <tbody>
                {
                  votes && votes.length > 0
                    ? votes.map((vote, index) => {
                      return (
                        <tr key={vote.voteId} className='text-break'>
                          <td className='text-center' style={{minWidth: '5rem'}}>{vote.amount}</td>
                          <td>{VoteList.getFormattedDate(vote.submittedOn)}</td>
                          <td>{vote.submitterLastName}</td>
                          <td>{vote.submitterFirstName}</td>
                          <td>{vote.submitterEmail}</td>
                          {
                            AuthHelper.isCurrentUserAdmin() &&
                            <td className='text-center' style={{minWidth: '3rem'}}>
                              <IconButton iconType={'delete'} buttonType={'button'} variant={'outline-danger'}
                                          size={"sm"}
                                          onClick={() => this.onDeleteVotes(vote.voteId, index, vote.amount)}
                              />
                            </td>
                          }
                        </tr>
                      );
                    })
                    : <tr>
                      <td colSpan={6} className={'text-center'}>No votes</td>
                    </tr>
                }
                </tbody>
              </Table>

              {
                this.state.totalPages > 1 &&
                <Row>
                  <Col md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}} className='mt-2'>
                    <Pagination current={this.state.page}
                                total={this.state.totalPages}
                                onPageChange={(page: number) => this.onVotePageChange(page)}
                    />
                  </Col>
                </Row>
              }
            </>
        }
      </>
    );
  }

  static getFormattedDate(submittedOn: string) {
    return submittedOn ? moment(submittedOn).format(MOMENT_DATE_FORMAT) : null;
  }

  async componentDidMount() {
    let entryId = this.props.entryId ?? 0;

    await this.getEntryVoteList(entryId, this.state.sorting);
  }

  private async getEntryVoteList(entryId: number, sorting: string, page?: number) {
      let request: IGetVotesRequest = {
      entryId: entryId,
      page: page ? page : this.state.page,
      sorting: sorting
    };

    try {
      let response = await Api.getEntryVoteList(request);

      this.setState({
        contestId: response.contestId,
        contestName: response.contestName,
        contestEntryId: response.contestEntryId,
        entryTitle: response.entryTitle,
        entryDescription: response.entryDescription,
        entryDefaultImage: response.entryDefaultImage,
        totalEntryVotes: response.pageOfVotes.totalEntryVotes,
        votes: response.pageOfVotes.votes,

        page: response.pageOfVotes.pager.page,
        totalPages: response.pageOfVotes.pager.totalPages,
        sorting: sorting,
        validationErrors: {},
        isLoading: false
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get entry vote list from the server', err)
      );
    }
  }

  private async onConfirmAddVotesClick(entryId: number, numberOfVotes: number) {
    this.setState({isLoading: true});

    let request: IAddVotesManuallyFromVoteListRequest = {
      entryId: entryId,
      votesAmount: numberOfVotes,
      page: this.state.page,
      sorting: this.state.sorting
    };

    try {
      let response = await Api.addVotesFromVoteList(request);

      let state = {...this.state};
      state.totalEntryVotes = response.totalEntryVotes;
      state.votes = response.votes;
      state.page = response.pager.page;
      state.totalPages = response.pager.totalPages;
      state.validationErrors = {};
      state.isLoading = false;
      this.setState(state);
    } catch (err) {
      this.setValidationErrors(Validations.buildApiCommunicationErrors('Can\'t add votes on the server', err));

      this.closeAddVotesDialog();
      return;
    }
    this.closeAddVotesDialog();
  }

  private onCancelAddVotesClick() {
    this.closeAddVotesDialog();
  }

  private closeAddVotesDialog() {
    let state = {...this.state};
    state.isAddVotesDialogOpen = false;
    this.setState(state);
  }

  private onAddVotes(): void {
    let state = {...this.state};
    state.isAddVotesDialogOpen = true;
    this.setState(state);
  }

  private onDeleteVotes(voteId: number, index: number, voteAmount: number): void {
    let state = {...this.state};
    state.voteIdToDelete = voteId;
    state.voteIndexToDelete = index;
    state.voteAmountToDelete = voteAmount;
    state.isDeleteVoteDialogOpen = true;
    this.setState(state);
  }

  private async onConfirmDeleteVoteClick(voteId: number) {
    this.setState({isLoading: true});

    let request: IDeleteVoteRequest = {
      voteId: voteId,
      page: this.state.page,
      sorting: this.state.sorting,
    };

    try {
      let response = await Api.deleteVote(request);

      let state = {...this.state};
      state.totalEntryVotes = response.totalEntryVotes;
      state.votes = response.votes;
      state.page = response.pager.page;
      state.totalPages = response.pager.totalPages;
      state.validationErrors = {};
      state.isLoading = false;
      this.setState(state);
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t delete vote on the server', err)
      );

      this.closeDeleteVoteDialog();
      return;
    }
    this.closeDeleteVoteDialog();
  }

  private onCancelDeleteVoteClick() {
    this.closeDeleteVoteDialog();
  }

  private closeDeleteVoteDialog() {
    let state = {...this.state};
    state.voteIdToDelete = undefined;
    state.voteIndexToDelete = undefined;
    state.voteAmountToDelete = undefined;
    state.isDeleteVoteDialogOpen = false;
    this.setState(state);
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }

  private onExportToExcelClick = async (entryId: number) => {
    this.setState({isLoading: true});

    let request: IExportVotesToExcelRequest = {
      entryId: entryId,
      sorting: this.state.sorting,
    };

    try {
      let response = await Api.exportVotesToExcel(request);

      this.setState({fileName: response.fileName, xlsxBase64: response.xlsxBase64, isLoading: false});

      ExportHelper.saveToExcel(response.fileName, response.xlsxBase64);
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t export to xlsx format', err)
      );
    }
  };

  private async sortByAmountClick() {
    if (this.state.sorting === VOTE_SORTING.Amount) {
      this.setState({isLoading: true});
      await this.getEntryVoteList(this.state.contestEntryId, VOTE_SORTING.AmountDescending);
    } else {
      this.setState({isLoading: true});
      await this.getEntryVoteList(this.state.contestEntryId, VOTE_SORTING.Amount);
    }
  }

  private async sortBySubmitTimeClick() {
    if (this.state.sorting === VOTE_SORTING.SubmitTime) {
      this.setState({isLoading: true});
      await this.getEntryVoteList(this.state.contestEntryId, VOTE_SORTING.SubmitTimeDescending);
    } else {
      this.setState({isLoading: true});
      await this.getEntryVoteList(this.state.contestEntryId, VOTE_SORTING.SubmitTime);
    }
  }

  private async sortBySubmitterFirstNameClick() {
    if (this.state.sorting === VOTE_SORTING.SubmitterFirstName) {
      this.setState({isLoading: true});
      await this.getEntryVoteList(this.state.contestEntryId, VOTE_SORTING.SubmitterFirstNameDescending);
    } else {
      this.setState({isLoading: true});
      await this.getEntryVoteList(this.state.contestEntryId, VOTE_SORTING.SubmitterFirstName);
    }
  }

  private async sortBySubmitterLastNameClick() {
    if (this.state.sorting === VOTE_SORTING.SubmitterLastName) {
      this.setState({isLoading: true});
      await this.getEntryVoteList(this.state.contestEntryId, VOTE_SORTING.SubmitterLastNameDescending);
    } else {
      this.setState({isLoading: true});
      await this.getEntryVoteList(this.state.contestEntryId, VOTE_SORTING.SubmitterLastName);
    }
  }

  private async sortBySubmitterEmailClick() {
    if (this.state.sorting === VOTE_SORTING.SubmitterEmail) {
      this.setState({isLoading: true});
      await this.getEntryVoteList(this.state.contestEntryId, VOTE_SORTING.SubmitterEmailDescending);
    } else {
      this.setState({isLoading: true});
      await this.getEntryVoteList(this.state.contestEntryId, VOTE_SORTING.SubmitterEmail);
    }
  }

  private async onVotePageChange(page: number) {
    if (this.state.page !== page) {
      this.setState({isLoading: true});
      await this.getEntryVoteList(this.state.contestEntryId, this.state.sorting, page);
    }
  }
}

export default withVotingAppAuthorize(VoteList);
