import React, { FC } from 'react';
import { Container } from "react-bootstrap-v5";

const UnauthorizedLayout: FC = ({children}) => {
  return (
    <Container fluid className='pt-3 pb-5'>
      {children}
    </Container>
  );
}

export default UnauthorizedLayout;
