import React, { Component } from 'react';
import { Spinner } from "react-bootstrap-v5";
import { ILoadingBarProps } from './ILoadingBarProps';
import styles from './LoadingBar.module.scss';

export default class LoadingBar extends Component<ILoadingBarProps> {
  render() {
    return (
      <div className={`${styles.LoaderContainer} ${this.props.className}`} style={this.props.styles}>
        <Spinner animation="border" variant="primary" size={this.props.size}/>
      </div>
    );
  }
}
