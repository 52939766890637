import { faEye, faHeart, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { Component } from "react";
import { Col, Row, Table } from "react-bootstrap-v5";
import { Redirect } from "react-router-dom";
import Api from "../../../../api/Api";
import {
  IAddManualVotesFromEntryDetailsRequest
} from "../../../../api/api-interfaces/entry/entry-details/IAddManualVotesFromEntryDetailsRequest";
import { IApproveEntryRequest } from "../../../../api/api-interfaces/entry/entry-details/IApproveEntryRequest";
import { IDetailsResponse } from "../../../../api/api-interfaces/entry/entry-details/IDetailsResponse";
import { IUnApproveEntryRequest } from "../../../../api/api-interfaces/entry/entry-details/IUnApproveEntryRequest";
import withVotingAppAuthorize from "../../../../components/common/authorize/withVotingAppAuthorize";
import IconButton from "../../../../components/common/buttons/icon-button/IconButton";
import CustomLink from "../../../../components/common/custom-link/CustomLink";
import ImageComponent from "../../../../components/common/image-component/ImageComponent";
import ImageGallery from "../../../../components/common/image-gallery/ImageGallery";
import LoadingBar from "../../../../components/common/loading-bar/LoadingBar";
import ValidationSummary from "../../../../components/common/validation-summary/ValidationSummary";
import { VerticalSpaceSize } from "../../../../components/common/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../../components/common/vertical-space/VerticalSpace";
import {
  CUSTOM_FIELD_CODES,
  CUSTOM_FIELDS_SHOW_ON_OPTIONS,
  KIWANIS_BLUE_COLOR,
  KIWANIS_GOLD_COLOR,
  MOMENT_DATE_FORMAT
} from "../../../../constants/Constants";
import AuthHelper from "../../../../helpers/auth-helper/AuthHelper";
import ValidationErrors from "../../../../helpers/validation-helper/ValidationErrors";
import Validations from "../../../../helpers/validation-helper/Validations";
import RoutingConstants from "../../../../routes/RoutingConstants";
import { img, thumb, thumbInner, thumbsContainer } from "../../../../styles/ImageSmallStyles";
import AddManualVotesModal from "../add-manual-votes-modal/AddManualVotesModal";
import DeleteEntryModal from "../delete-entry-modal/DeleteEntryModal";
import styles from './EntryDetails.module.scss';
import { IEntryDetailsProps } from "./IEntryDetailsProps";
import { IEntryDetailsState } from "./IEntryDetailsState";
import UnapproveEntryModal from "./unapprove-entry-modal/UnapproveEntryModal";

class EntryDetails extends Component<IEntryDetailsProps, IEntryDetailsState> {
  constructor(props: IEntryDetailsProps) {
    super(props);

    this.state = {
      contestId: null,
      entryId: null,
      contestName: "",
      title: "",
      description: "",
      approvedOn: "",
      approverFullName: "",
      viewsCount: 0,
      votesCount: 0,
      defaultPhotoUrl: "",
      allPhotoUrls: [],
      fields: [],
      submittedOn: "",
      submitterFullName: "",
      isApproved: false,
      canBeApproved: false,
      isWinner: false,

      isImageGalleryOpen: false,
      indexOfImages: 0,
      isAddVotesDialogOpen: false,

      isDeleteEntryDialogOpen: false,
      entryIdToDelete: undefined,
      entryNameToDelete: undefined,

      isUnapproveEntryDialogOpen: false,
      entryNameToUnapprove: undefined,

      isLoading: true,
      validationErrors: null,
      excludeKeys: [],
      redirect: null
    };
  }

  render() {
    if (this.state.redirect !== null) {
      return <Redirect push to={this.state.redirect}/>;
    }

    let {
      contestId, entryId, contestName, title, description, approvedOn, viewsCount, votesCount,
      defaultPhotoUrl, allPhotoUrls, fields, submittedOn, isApproved, approverFullName, submitterFullName, isWinner
    } = this.state;

    return (
      <>
        {
          this.state.validationErrors &&
          <Row>
            <Col md={{span: 6, offset: 3}}>
              <ValidationSummary errors={this.state.validationErrors} excludeKeys={this.state.excludeKeys}/>
            </Col>
          </Row>
        }

        {
          this.state.isLoading ? <LoadingBar/> :
            <>
              <AddManualVotesModal
                isLoading={this.state.isLoading}
                totalNumberOfVotes={votesCount}
                entryId={entryId ?? 0}
                entryName={title}
                showDialog={this.state.isAddVotesDialogOpen}
                onConfirm={this.onConfirmAddVotesClick.bind(this)}
                onCancel={this.onCancelAddVotesClick.bind(this)}
              />

              <DeleteEntryModal
                isLoading={this.state.isLoading}
                showDialog={this.state.isDeleteEntryDialogOpen}
                entryIdToDelete={entryId ?? 0}
                entryNameToDelete={title}
                onConfirm={this.onConfirmDeleteEntryClick.bind(this)}
                onCancel={this.onCancelDeleteEntryClick.bind(this)}
              />

              <UnapproveEntryModal
                isLoading={this.state.isLoading}
                showDialog={this.state.isUnapproveEntryDialogOpen}
                entryNameToUnapprove={title}
                onConfirm={this.onConfirmUnapproveEntryClick.bind(this)}
                onCancel={this.onCancelUnapproveEntryClick.bind(this)}
              />

              <h1 className={"text-break headline"}>{title}</h1>

              <p className='text-break'>{description}</p>

              {
                AuthHelper.isCurrentUserAdmin() &&
                <>
                  <div className='d-flex gap-2 flex-wrap justify-content-start'>
                    <CustomLink to={RoutingConstants.buildEditEntryUrl(contestId ?? 0, entryId ?? 0)}>
                      <IconButton size={'sm'} variant={'primary'} title={'Edit entry'}/>
                    </CustomLink>

                    {
                      isApproved
                        ? <IconButton size={'sm'} variant={'primary'} title={'Un-approve entry'}
                                      onClick={this.onUnApproveEntry.bind(this)}/>
                        :
                        <span title={!this.state.canBeApproved
                          ? 'Approving new entries is not allowed for contests with ended voting.' : ''}
                        >
                          <IconButton size={'sm'} variant={'primary'} title={'Approve entry'}
                                      onClick={this.onApproveEntry.bind(this)} disabled={!this.state.canBeApproved}/>
                        </span>
                    }

                    <IconButton size={'sm'} buttonType={'button'} variant={'danger'} title={'Delete entry'}
                                onClick={() => this.onDeleteEntry(entryId ?? 0, title)}/>
                  </div>
                  <hr/>
                </>
              }

              <Row>
                <Col md={{span: 6, offset: 3}} lg={{span: 4, offset: 0}} className='mb-3'>
                  <ImageComponent src={defaultPhotoUrl}
                                  onClick={() => this.setState({isImageGalleryOpen: true, indexOfImages: 0})}
                  />

                  {
                    allPhotoUrls && allPhotoUrls.length > 1 &&
                    <aside style={thumbsContainer} className='mt-2'>
                      {
                        allPhotoUrls.map((url, index) => {
                          return (
                            <div style={thumb} key={index}>
                              <div style={thumbInner}>
                                <img style={img} src={url} alt={'entry img'} className='cursor_pointer'
                                     onClick={() => this.setState({isImageGalleryOpen: true, indexOfImages: index})}/>
                              </div>
                            </div>
                          );
                        })
                      }
                    </aside>
                  }
                </Col>

                <ImageGallery
                  isOpen={this.state.isImageGalleryOpen}
                  indexOfImages={this.state.indexOfImages}
                  images={allPhotoUrls}
                  onCloseRequest={() => this.setState({isImageGalleryOpen: false})}
                  onMovePrevRequest={() =>
                    this.setState({indexOfImages: (this.state.indexOfImages + allPhotoUrls.length - 1) % allPhotoUrls.length})
                  }
                  onMoveNextRequest={() =>
                    this.setState({indexOfImages: (this.state.indexOfImages + allPhotoUrls.length + 1) % allPhotoUrls.length})
                  }
                />

                <Col lg={8}>
                  <Row>
                    <Col>
                      <b>Contest</b>
                      <div className="text-secondary text-break">
                        {contestName}
                      </div>
                    </Col>
                  </Row>

                  <Row className='my-2'>
                    <Col>
                      <b>Registration date</b>
                      <div className="text-secondary text-break">
                        {submittedOn ? moment(submittedOn).format(MOMENT_DATE_FORMAT) : null}
                      </div>
                    </Col>
                  </Row>

                  <Row className='my-2'>
                    <Col>
                      <b>Submitter</b>
                      <div className="text-secondary">
                        {
                          submitterFullName
                            ? <span className='text-break'>{submitterFullName}</span>
                            : <>(not set)</>
                        }
                      </div>
                    </Col>
                  </Row>

                  <Row className='my-2'>
                    <Col>
                      <b>Number of votes</b>
                      <div className="text-secondary">
                        {
                          isWinner && <span><FontAwesomeIcon icon={faTrophy} color={KIWANIS_GOLD_COLOR}/>&nbsp;</span>
                        }
                        <FontAwesomeIcon icon={faHeart} color={KIWANIS_BLUE_COLOR}/>&nbsp;
                        {votesCount}

                        <span className='mx-2'>
                          {
                            AuthHelper.isCurrentUserAdmin() &&
                            <IconButton iconType={'add'} variant={'outline-primary'} size={"sm"}
                                        onClick={this.onAddVotes.bind(this)}/>
                          }
                        </span>

                        <CustomLink to={RoutingConstants.buildEntryVoteListUrl(entryId ?? 0)}>
                          <IconButton variant={'outline-primary'} size={"sm"} title='Vote list'/>
                        </CustomLink>
                      </div>
                    </Col>
                  </Row>

                  <Row className='my-2'>
                    <Col>
                      <b>Number of views</b>
                      <div className="text-secondary">
                        <FontAwesomeIcon icon={faEye} color={KIWANIS_BLUE_COLOR}/> {viewsCount}
                      </div>
                    </Col>
                  </Row>

                  <Row className='my-2'>
                    <Col>
                      <b>Approve date</b>

                    </Col>
                    <div className="text-secondary">
                      {
                        isApproved
                          ? moment(approvedOn).format(MOMENT_DATE_FORMAT)
                          : '(not approved)'
                      }
                    </div>
                  </Row>

                  <Row className='my-2'>
                    <Col>
                      <b>Approver</b>
                      <div className="text-secondary">
                        {
                          approverFullName
                            ? <span className='text-break'>{approverFullName}</span>
                            : <>(not set)</>
                        }
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <VerticalSpace size={VerticalSpaceSize.small}/>

              <h3>Fields</h3>
              <Table striped bordered responsive className={styles.columnWith}>
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Value</th>
                  <th>Description</th>
                  <th>Show on</th>
                </tr>
                </thead>
                <tbody>
                {
                  fields && fields.length > 0
                    ? fields.map((field, index) => {
                      switch (field.fieldTypeCode) {
                        case CUSTOM_FIELD_CODES.Text:
                          return (
                            <tr key={index} className="text-break">
                              <td>{field.name}</td>
                              <td>{field.stringValue}</td>
                              <td>{field.description}</td>
                              <td>{this.getShowOnName(field.showOnCode)}</td>
                            </tr>
                          );
                        case CUSTOM_FIELD_CODES.TextArea:
                          return (
                            <tr key={index} className="text-break">
                              <td>{field.name}</td>
                              <td><span style={{whiteSpace: "pre-line"}}>{field.stringValue}</span></td>
                              <td>{field.description}</td>
                              <td>{this.getShowOnName(field.showOnCode)}</td>
                            </tr>
                          );
                        case CUSTOM_FIELD_CODES.RadioButton:
                          return (
                            <tr key={index} className="text-break">
                              <td>{field.name}</td>
                              <td>{field.stringValue}</td>
                              <td>{field.description}</td>
                              <td>{this.getShowOnName(field.showOnCode)}</td>
                            </tr>
                          );
                        case CUSTOM_FIELD_CODES.DropDownList:
                          return (
                            <tr key={index} className="text-break">
                              <td>{field.name}</td>
                              <td>{field.stringValue}</td>
                              <td>{field.description}</td>
                              <td>{this.getShowOnName(field.showOnCode)}</td>
                            </tr>
                          );
                        case CUSTOM_FIELD_CODES.Checkbox:
                          return (
                            <tr key={index} className="text-break">
                              <td>{field.name}</td>
                              <td>{field.boolValue ? 'Yes' : 'No'}</td>
                              <td>{field.description}</td>
                              <td>{this.getShowOnName(field.showOnCode)}</td>
                            </tr>
                          );
                        case CUSTOM_FIELD_CODES.DatePicker:
                          return (
                            <tr key={index} className="text-break">
                              <td>{field.name}</td>
                              <td>{field.dateTimeValue ? moment(field.dateTimeValue).format(MOMENT_DATE_FORMAT) : null}</td>
                              <td>{field.description}</td>
                              <td>{this.getShowOnName(field.showOnCode)}</td>
                            </tr>
                          );
                        default:
                          return (
                            <tr>
                              <td colSpan={4} className={'text-center'}>Invalid field</td>
                            </tr>
                          );
                      }
                    })
                    : <td colSpan={4} className={'text-center'}>No custom fields</td>
                }
                </tbody>
              </Table>
            </>
        }
      </>
    );
  }

  async componentDidMount() {
    let entryId = this.props.entryId ?? 0;
    this.setState({entryId: entryId});

    await this.getEntryDetails(entryId);
  }

  private async getEntryDetails(entryId: number) {
    try {
      let response = await Api.getEntryDetails(entryId);

      this.saveDetailsResponseInState(response);
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get entry details from the server', err)
      );
    }
  }

  private async onApproveEntry() {
    this.setState({isLoading: true});

    let request: IApproveEntryRequest = {entryId: this.state.entryId ?? 0,};

    try {
      let response = await Api.approveEntry(request);

      this.saveDetailsResponseInState(response);
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t approve contest entry on the server', err)
      );
    }
  }

  private async onUnApproveEntry() {
    await this.getEntryDetails(this.state.entryId ?? 0);

    if (!this.state.canBeApproved) {
      this.openUnapproveEntryDialog(this.state.title)
    } else {
      this.setState({isLoading: true});

      let request: IUnApproveEntryRequest = {entryId: this.state.entryId ?? 0};

      try {
        let response = await Api.unApproveEntry(request);

        this.saveDetailsResponseInState(response);
      } catch (err) {
        this.setValidationErrors(
          Validations.buildApiCommunicationErrors('Can\'t un-approve  contest entry on the server', err)
        );
      }
    }
  }

  private async onConfirmAddVotesClick(entryId: number, numberOfVotes: number) {
    this.setState({isLoading: true});

    let request: IAddManualVotesFromEntryDetailsRequest = {entryId: entryId, numberOfVotes: numberOfVotes};

    try {
      let response = await Api.addVotesFromEntryDetails(request);

      this.saveDetailsResponseInState(response);
    } catch (err) {
      this.setValidationErrors(Validations.buildApiCommunicationErrors('Can\'t add votes on the server', err));

      this.closeAddVotesDialog();
      return;
    }
    this.closeAddVotesDialog();
  }

  private saveDetailsResponseInState(response: IDetailsResponse): void {
    let state = {...this.state};
    state.contestId = response.contestId;
    state.contestName = response.contestName;
    state.title = response.title;
    state.description = response.description;
    state.approvedOn = response.approvedOn;
    state.approverFullName = response.approverFullName;
    state.viewsCount = response.viewsCount;
    state.votesCount = response.votesCount;
    state.defaultPhotoUrl = response.defaultPhotoUrl;
    state.allPhotoUrls = response.allPhotoUrls;
    state.fields = response.fields;
    state.submittedOn = response.submittedOn;
    state.submitterFullName = response.submitterFullName;
    state.isApproved = response.isApproved;
    state.canBeApproved = response.canBeApproved;
    state.isWinner = response.isWinner;
    state.isLoading = false;
    this.setState(state);
  }

  private onCancelAddVotesClick() {
    this.closeAddVotesDialog();
  }

  private closeAddVotesDialog() {
    let state = {...this.state};
    state.isAddVotesDialogOpen = false;
    this.setState(state);
  }

  private async onAddVotes() {
    let state = {...this.state};
    state.isAddVotesDialogOpen = true;
    this.setState(state);
  }

  private async onDeleteEntry(entryId: number, entryName: string) {
    let state = {...this.state};
    state.entryNameToDelete = entryName;
    state.entryIdToDelete = entryId;
    state.isDeleteEntryDialogOpen = true;
    this.setState(state);
  }

  private async onConfirmDeleteEntryClick(entryId: number) {
    this.setState({isLoading: true});

    try {
      await Api.deleteEntryFromEntryDetails(entryId);

      this.setState({
        redirect: RoutingConstants.buildContestDetailsUrl(this.state.contestId ?? 0)
      });
    } catch (err) {
      this.setValidationErrors(Validations.buildApiCommunicationErrors('Can\'t delete contest entry', err));

      this.closeDeleteEntryDialog();
      return;
    }
    this.closeDeleteEntryDialog();
  }

  private onCancelDeleteEntryClick() {
    this.closeDeleteEntryDialog();
  }

  private closeDeleteEntryDialog() {
    let state = {...this.state};
    state.entryIdToDelete = undefined;
    state.entryNameToDelete = undefined;
    state.isDeleteEntryDialogOpen = false;
    this.setState(state);
  }

  private openUnapproveEntryDialog(entryName: string) {
    let state = {...this.state};
    state.entryNameToUnapprove = entryName;
    state.isUnapproveEntryDialogOpen = true;
    this.setState(state);
  }

  private async onConfirmUnapproveEntryClick() {
    this.setState({isLoading: true});

    let request: IUnApproveEntryRequest = {entryId: this.state.entryId ?? 0,};

    try {
      let response = await Api.unApproveEntry(request);

      this.saveDetailsResponseInState(response);
    } catch (err) {
      this.setValidationErrors(Validations.buildApiCommunicationErrors('Can\'t un-approve contest entry on the server', err));

      this.closeUnapproveEntryDialog();
      return;
    }
    this.closeUnapproveEntryDialog();
  }

  private onCancelUnapproveEntryClick() {
    this.closeUnapproveEntryDialog();
  }

  private closeUnapproveEntryDialog() {
    let state = {...this.state};
    state.entryNameToUnapprove = undefined;
    state.isUnapproveEntryDialogOpen = false;
    this.setState(state);
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }

  getShowOnName(showOnCode: string): string {
    let showOn = CUSTOM_FIELDS_SHOW_ON_OPTIONS.find(x => x.code === showOnCode);
    if (showOn) {
      return showOn.name;
    } else return '';
  }
}

export default withVotingAppAuthorize(EntryDetails);
