import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Image } from "react-bootstrap-v5";
import { KIWANIS_GOLD_COLOR } from "../../../constants/Constants";
import imageStyles from "../../../styles/ImageBigStyles.module.scss";
import { ImageComponentProps } from "./ImageComponentProps";

class ImageComponent extends Component<ImageComponentProps> {
  render() {
    return <div className={`${imageStyles.vaContainer}`}>
      <Image src={this.props.src} alt="entry img" className={imageStyles.vaImage} fluid thumbnail
             onClick={this.onClick.bind(this)}
      />
      <div className={imageStyles.vaOverlay}
           onClick={this.onClick.bind(this)}>
        <div className={imageStyles.vaText}>
          <FontAwesomeIcon size={"3x"} icon={faSearch} color={KIWANIS_GOLD_COLOR}/>
        </div>
      </div>
    </div>;
  }

  private onClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }
}

export default ImageComponent;