import ValidationErrors from "../../../helpers/validation-helper/ValidationErrors";

export default class BadRequestError extends Error {
  public cause: any;
  public validationErrors: ValidationErrors;

  constructor(validationErrors: ValidationErrors, cause?: any) {
    super('Validation error: ' + JSON.stringify(validationErrors));
    Object.setPrototypeOf(this, new.target.prototype);

    this.cause = cause;
    this.validationErrors = validationErrors;

  }
}