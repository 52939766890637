import React, { Component } from 'react';
import { Col, Row } from "react-bootstrap-v5";
import { Redirect } from 'react-router-dom';
import ValidationSummary from "../../components/common/validation-summary/ValidationSummary";
import { VerticalSpaceSize } from "../../components/common/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../components/common/vertical-space/VerticalSpace";
import ValidationErrors from "../../helpers/validation-helper/ValidationErrors";
import Validations from "../../helpers/validation-helper/Validations";
import { IUnsubscribeProps } from "./IUnsubscribeProps";
import { IUnsubscribeState } from "./IUnsubscribeState";
import IconButton from "../../components/common/buttons/icon-button/IconButton";
import RoutingConstants from "../../routes/RoutingConstants";
import Api from "../../api/Api";
import LoadingBar from "../../components/common/loading-bar/LoadingBar";
import { IUnsubscribeFromEmailRequest } from "../../api/api-interfaces/unsubscribe/IUnsubscribeFromEmailRequest";
import AuthHelper from "../../helpers/auth-helper/AuthHelper";

class Unsubscribe extends Component<IUnsubscribeProps, IUnsubscribeState> {
  constructor(props: IUnsubscribeProps) {
    super(props);

    this.state = {
      contestName: "",
      subscriptionId: undefined,
      isSuccessfullyUnsubscribed: false,
      isUnsubscribeCancelled: false,
      isAlreadyUnsubscribed: false,

      validationErrors: null,
      excludeKeys: [],
      isLoading: true,
      redirect: null
    };
  }

  render() {
    if (this.state.redirect !== null) {
      return <Redirect push to={this.state.redirect}/>;
    }

    return (
      <>
        {
          this.state.validationErrors &&
          <Row>
            <Col md={{span: 6, offset: 3}}>
              <ValidationSummary errors={this.state.validationErrors} excludeKeys={this.state.excludeKeys}/>
            </Col>
          </Row>
        }

        {
          this.state.isLoading ? <LoadingBar/> :
            <>
              <VerticalSpace size={VerticalSpaceSize.large}/>

              <div className="text-center mt-5">
                <h1 className={"headline"}>Unsubscribe</h1>

                {
                  !this.state.isSuccessfullyUnsubscribed && !this.state.isUnsubscribeCancelled &&
                  <>
                    {
                      !this.state.isAlreadyUnsubscribed &&
                      <Row>
                        <p>Are you sure you want to unsubscribe from entry submission notifications in contest <br/>
                          "<b>{this.state.contestName}</b>" ?
                        </p>
                      </Row>
                    }

                    {
                      !this.state.isAlreadyUnsubscribed &&
                      <div className="d-flex justify-content-center">
                        <IconButton onClick={this.onUnsubscribeClick.bind(this)} variant="primary" title="Unsubscribe"
                                    styles={{marginRight: '1em'}} disabled={this.state.isLoading}/>

                        <IconButton onClick={this.onCancelClick.bind(this)} variant="primary" title="Cancel"/>
                      </div>
                    }

                    {
                      this.state.isAlreadyUnsubscribed &&
                      <div className="d-flex justify-content-center">
                        {
                          AuthHelper.isTokenSet()
                            ? <IconButton onClick={this.onBackToSiteClick.bind(this)} variant="primary"
                                          title="Back to site"
                                          styles={{marginRight: '1em'}} disabled={this.state.isLoading}/>

                            : <IconButton onClick={this.onSignInClick.bind(this)} variant="primary" title="Sign In"
                                          styles={{marginRight: '1em'}} disabled={this.state.isLoading}/>
                        }

                        <IconButton onClick={this.onCloseTabClick.bind(this)} variant="primary" title="Close tab"/>
                      </div>
                    }
                  </>
                }

                {
                  this.state.isSuccessfullyUnsubscribed &&
                  <>
                    <Row>
                      <p>You have been successfully unsubscribed.</p>
                    </Row>

                    <div className="d-flex justify-content-center">
                      {
                        AuthHelper.isTokenSet()
                          ? <IconButton onClick={this.onBackToSiteClick.bind(this)} variant="primary" title="Back to site"
                                        styles={{marginRight: '1em'}} disabled={this.state.isLoading}/>

                          : <IconButton onClick={this.onSignInClick.bind(this)} variant="primary" title="Sign In"
                                        styles={{marginRight: '1em'}} disabled={this.state.isLoading}/>
                      }

                      <IconButton onClick={this.onCloseTabClick.bind(this)} variant="primary" title="Close tab"/>
                    </div>
                  </>
                }

                {
                  this.state.isUnsubscribeCancelled &&
                  <>
                    <Row>
                      <p>Nothing has changed.</p>
                    </Row>

                    <div className="d-flex justify-content-center">
                      {
                        AuthHelper.isTokenSet()
                          ? <IconButton onClick={this.onBackToSiteClick.bind(this)} variant="primary" title="Back to site"
                                        styles={{marginRight: '1em'}} disabled={this.state.isLoading}/>

                          : <IconButton onClick={this.onSignInClick.bind(this)} variant="primary" title="Sign In"
                                        styles={{marginRight: '1em'}} disabled={this.state.isLoading}/>
                      }

                      <IconButton onClick={this.onCloseTabClick.bind(this)} variant="primary" title="Close tab"/>
                    </div>
                  </>
                }

              </div>
            </>
        }
      </>
    );
  }

  async componentDidMount() {
    let subscriptionId = this.props.subscriptionId ?? '';
    this.setState({subscriptionId: subscriptionId});

    await this.getUnsubscribeDetails(subscriptionId);
  }

  private async getUnsubscribeDetails(subscriptionId: string) {
    try {
      let response = await Api.getUnsubscribeDetails(subscriptionId);

      this.setState({
        contestName: response.contestName,
        isLoading: false,
        validationErrors: {}
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get unsubscribe details from the server', err)
      );
    }
  }

  private async onUnsubscribeClick() {
    this.setState({isLoading: true});

    let request: IUnsubscribeFromEmailRequest = {
      subscriptionId: this.state.subscriptionId ?? ''
    }

    try {
      await Api.Unsubscribe(request);

      this.setState({
        isSuccessfullyUnsubscribed: true,
        isLoading: false,
        validationErrors: {}
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors(
          'Can\'t unsubscribe user from contest entry submission notifications on the server', err
        )
      );
    }
  }

  onCancelClick() {
    this.setState({
      isUnsubscribeCancelled: true
    })
  }

  onBackToSiteClick() {
    this.setState({redirect: RoutingConstants.HOME});
  }

  onSignInClick() {
    this.setState({redirect: RoutingConstants.LOGIN});
  }

  onCloseTabClick() {
    window.close()
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isAlreadyUnsubscribed = true;
    state.isLoading = false;
    this.setState(state);
  }
}

export default Unsubscribe;