import React, { Component } from "react";
import { Col, Form, InputGroup, Row, Table } from "react-bootstrap-v5";
import Pagination from 'react-responsive-pagination';
import { Redirect } from "react-router-dom";
import Api from "../../api/Api";
import { IGetUserListRequest } from "../../api/api-interfaces/users/IGetUserListRequest";
import withVotingAppAuthorize from "../../components/common/authorize/withVotingAppAuthorize";
import IconButton from "../../components/common/buttons/icon-button/IconButton";
import CustomLink from "../../components/common/custom-link/CustomLink";
import LoadingBar from "../../components/common/loading-bar/LoadingBar";
import SortingTableTitle from "../../components/common/sorting-table-title/SortingTableTitle";
import ValidationSummary from "../../components/common/validation-summary/ValidationSummary";
import { VerticalSpaceSize } from "../../components/common/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../components/common/vertical-space/VerticalSpace";
import { USER_SORTING } from "../../constants/Constants";
import { scrollToIdHelper } from "../../helpers/scroll-into-view-helper/scrollToIdHelper";
import ValidationErrors from "../../helpers/validation-helper/ValidationErrors";
import Validations from "../../helpers/validation-helper/Validations";
import RoutingConstants from "../../routes/RoutingConstants";
import DeleteUserConfirmationModal from "./delete-user-confirmation-modal/DeleteUserConfirmationModal";
import { IUsersProps } from "./IUsersProps";
import { IUsersState } from "./IUsersState";
import styles from './Users.module.scss';

class Users extends Component<IUsersProps, IUsersState> {
  constructor(props: IUsersProps) {
    super(props);

    this.state = {
      userId: 0,
      users: null,
      sorting: USER_SORTING.LastName,
      searchKeyword: '',
      page: 1,
      totalPages: 1,

      isDeleteDialogOpen: false,
      userIdToDelete: undefined,
      userEmailToDelete: undefined,

      isLoading: true,
      validationErrors: null,
      excludeKeys: [],
      redirect: null,
    };

    this.onConfirmDeleteUserClick = this.onConfirmDeleteUserClick.bind(this);
    this.onCancelDeleteUserClick = this.onCancelDeleteUserClick.bind(this);
  }

  render() {
    if (this.state.redirect !== null) {
      let redirect = this.state.redirect;
      return <Redirect push to={redirect}/>;
    }

    return (
      <>
        {
          this.state.validationErrors &&
          <Row>
              <Col md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}}>
                  <ValidationSummary errors={this.state.validationErrors} excludeKeys={this.state.excludeKeys}/>
              </Col>
          </Row>
        }

        {
          this.state.isLoading ? <LoadingBar/> :
            <>
              <DeleteUserConfirmationModal
                isLoading={this.state.isLoading}
                showDialog={this.state.isDeleteDialogOpen}
                userIdToDelete={this.state.userIdToDelete}
                userEmailToDelete={this.state.userEmailToDelete}
                onConfirm={this.onConfirmDeleteUserClick}
                onCancel={this.onCancelDeleteUserClick}
              />

              <h1 className={'headline'}>Users</h1>

              <div className='mb-3'>
                <CustomLink to={RoutingConstants.buildCreateUserUrl(this.state.page, this.state.sorting)}>
                  <IconButton buttonType={'button'} variant={'primary'} size={"sm"} title={'Add new user'}/>
                </CustomLink>
              </div>

              <Form onSubmit={this.onFilterSubmit.bind(this)}>
                <Row>
                  <Col sm={10} md={8} lg={5} xxl={4}>
                    <InputGroup>
                      <Form.Control type="text" placeholder="Search"
                                    value={this.state.searchKeyword}
                                    onChange={this.onFilterChange.bind(this)}
                      />
                      <IconButton buttonType='submit' variant='outline-primary' title={'Filter'}/>
                    </InputGroup>
                  </Col>
                </Row>
              </Form>
              <VerticalSpace size={VerticalSpaceSize.small}/>

              <Table striped bordered responsive className={styles.columnWith}>
                <thead>
                <tr>
                  <th>
                    <SortingTableTitle name="Email"
                                       sorting={this.state.sorting}
                                       sortBy={USER_SORTING.Email}
                                       sortByDesc={USER_SORTING.EmailDescending}
                                       onSortByClick={this.sortByEmailClick.bind(this)}
                    />
                  </th>
                  <th>
                    <SortingTableTitle name="First name"
                                       sorting={this.state.sorting}
                                       sortBy={USER_SORTING.FirstName}
                                       sortByDesc={USER_SORTING.FirstNameDescending}
                                       onSortByClick={this.sortByFirstNameClick.bind(this)}
                    />
                  </th>
                  <th>
                    <SortingTableTitle name="Last name"
                                       sorting={this.state.sorting}
                                       sortBy={USER_SORTING.LastName}
                                       sortByDesc={USER_SORTING.LastNameDescending}
                                       onSortByClick={this.sortByLastNameClick.bind(this)}
                    />
                  </th>
                  <th>Role</th>
                  <th className='text-center'/>
                </tr>
                </thead>
                <tbody>
                {
                  this.state.users && this.state.users.length > 0
                    ? this.state.users.map(user => {
                      return (
                        <tr id={`user_${user.id}`} key={user.id}>
                          <td className='text-break'>{user.email}</td>
                          <td className='text-break'>{user.firstName}</td>
                          <td className='text-break'>{user.lastName}</td>
                          <td>{user.isAdmin ? 'Admin' : ''}</td>
                          <td className={styles.btnColumn}>
                            <span className="d-flex flex-wrap gap-1 justify-content-center">
                              <CustomLink to={RoutingConstants.buildUpdateUserUrl(user.id, this.state.page, this.state.sorting)}>
                                <IconButton iconType={'edit'} size={"sm"} buttonType={'button'}
                                            variant={'outline-primary'}/>
                              </CustomLink>
                              <IconButton onClick={() => this.onUserDeleteClick(user.id)}
                                          iconType={"delete"} size={"sm"}
                                          variant={"outline-danger"}/>
                            </span>
                          </td>
                        </tr>
                      );
                    })
                    : <tr>
                      <td colSpan={6} className='text-center'>No users</td>
                    </tr>
                }
                </tbody>
              </Table>

              {
                this.state.totalPages > 1 &&
                <Row>
                    <Col md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}} className='mt-2'>
                        <Pagination current={this.state.page}
                                    total={this.state.totalPages}
                                    onPageChange={(page: number) => this.onCurrentPageChange(page)}
                        />
                    </Col>
                </Row>
              }
            </>
        }
      </>
    );
  }

  async componentDidMount() {
    let userId = this.props.userId;
    let page = this.props.page;

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const sortingParam = params.get('sorting');
    const sorting = sortingParam ? sortingParam : this.state.sorting;

    this.setState({userId: userId});

    await this.getUsers(sorting, page);

    if (userId) {
      scrollToIdHelper(`user_${userId}`);
    }
  }

  async componentDidUpdate(prevProps: Readonly<IUsersProps>, prevState: Readonly<IUsersState>) {
    if (this.props.page !== prevProps.page) {
      this.setState({isLoading: true, redirect: null});
      await this.getUsers(this.state.sorting, this.props.page);
    }
  }

  private async getUsers(sorting: string, page: number) {
    let request: IGetUserListRequest = {
      sorting: sorting,
      page: page,
      searchKeyword: this.state.searchKeyword
    };

    try {
      let response = await Api.getUsers(request);

      this.setState({
        users: response.users,
        page: response.pager.page,
        totalPages: response.pager.totalPages,
        sorting: sorting,
        isLoading: false
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get users from the server', err)
      );
    }
  }

  private async sortByEmailClick() {
    if (this.state.sorting === USER_SORTING.Email) {
      this.setState({isLoading: true});
      await this.getUsers(USER_SORTING.EmailDescending, this.state.page);
    } else {
      this.setState({isLoading: true});
      await this.getUsers(USER_SORTING.Email, this.state.page);
    }
  }

  private async sortByFirstNameClick() {
    if (this.state.sorting === USER_SORTING.FirstName) {
      this.setState({isLoading: true});
      await this.getUsers(USER_SORTING.FirstNameDescending, this.state.page);
    } else {
      this.setState({isLoading: true});
      await this.getUsers(USER_SORTING.FirstName, this.state.page);
    }
  }

  private async sortByLastNameClick() {
    if (this.state.sorting === USER_SORTING.LastName) {
      this.setState({isLoading: true});
      await this.getUsers(USER_SORTING.LastNameDescending, this.state.page);
    } else {
      this.setState({isLoading: true});
      await this.getUsers(USER_SORTING.LastName, this.state.page);
    }
  }

  private onFilterChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({searchKeyword: event.target.value});
  }

  private async onFilterSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    this.setState({isLoading: true});
    await this.getUsers(this.state.sorting,1);
  }

  private async onCurrentPageChange(page: number) {
    if (this.state.page !== page) {
      this.setState({page: page, redirect: RoutingConstants.buildUsersUrl(page, this.state.userId)});
    }
  }

  private onUserDeleteClick(userId: number) {
    let state = {...this.state};
    state.userIdToDelete = userId;
    let matchingUsers = state.users?.filter(x => x.id === userId);
    if (matchingUsers !== undefined && matchingUsers !== null && matchingUsers.length > 0) {
      let user = matchingUsers[0];
      state.userEmailToDelete = user.email;
      state.isDeleteDialogOpen = true;
      this.setState(state);
    }
  }

  private async onConfirmDeleteUserClick(userId: number) {
    this.setState({isLoading: true});

    try {
      await Api.deleteUser(userId);
      await this.getUsers(this.state.sorting, this.state.page);
    } catch (err) {
      this.setValidationErrors(Validations.buildApiCommunicationErrors('Can\'t delete user', err));
      this.closeDeleteUserDialog();
      return;
    }

    this.closeDeleteUserDialog();
  }

  private onCancelDeleteUserClick() {
    this.closeDeleteUserDialog();
  }

  private closeDeleteUserDialog() {
    let state = {...this.state};
    state.userIdToDelete = undefined;
    state.userEmailToDelete = undefined;
    state.isDeleteDialogOpen = false;
    this.setState(state);
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }
}

export default withVotingAppAuthorize(Users);