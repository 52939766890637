import React, { Component } from 'react';
import ReactModal from 'react-modal';
import IconButton from "../../../../components/common/buttons/icon-button/IconButton";
import styles from '../../../../styles/ModalWindow.module.scss';
import { IDeleteEntryModalProps } from "./IDeleteEntryModalProps";
import { IDeleteEntryModalState } from "./IDeleteEntryModalState";

class DeleteEntryModal extends Component<IDeleteEntryModalProps, IDeleteEntryModalState> {

  private static dialogIndex = 100;

  constructor(props: IDeleteEntryModalProps) {
    super(props);

    this.state = {
      dialogId: 'delete_entry_dialog_' + (DeleteEntryModal.dialogIndex++)
    };

    this.onConfirmClicked = this.onConfirmClicked.bind(this);
  }

  render() {
    return (
      <ReactModal isOpen={this.props.showDialog}
                  id={this.state.dialogId}
                  shouldCloseOnOverlayClick={false}
                  shouldCloseOnEsc={false}
                  className={`${styles.content} shadow p-4`}
                  overlayClassName={`${styles.overlay}`}
                  appElement={document.getElementById('root') as HTMLElement}
      >

        <h2>Delete entry</h2>

        <p>Are you sure you want to delete <b className={"text-break"}> {this.props.entryNameToDelete}</b>?</p>

        <div className="d-flex justify-content-end">
          <IconButton disabled={this.props.isLoading} styles={{marginRight: '1em'}}
                      onClick={this.onConfirmClicked} variant="outline-danger" title="Delete"/>
          <IconButton onClick={this.props.onCancel} variant="outline-secondary" title="Cancel"/>
        </div>
      </ReactModal>
    );
  }

  onConfirmClicked() {
    if (this.props.onConfirm) {
      this.props.onConfirm(this.props.entryIdToDelete ?? 0);
    }
  }
}

export default DeleteEntryModal;