import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import RoutingConstants from "../../../routes/RoutingConstants";
import withVotingAppAuthorize from './withVotingAppAuthorize';
import { IAuthorizeProps } from './IAuthorizeProps';

class Authorize extends Component<IAuthorizeProps> {
  render() {
    if (this.props.auth.isAuthenticated) {
      return <React.Fragment>{this.props.children}</React.Fragment>;
    }

    return <Redirect push to={RoutingConstants.LOGIN}/>;
  }
}

export default withVotingAppAuthorize(Authorize);