import React, { FC, ReactElement } from 'react';
import { BrowserRouter } from "react-router-dom";
import './App.scss';
import Toast from "./components/common/toast/Toast";
import Routes from "./routes/Routes";

const App: FC = (): ReactElement => {
  return (
    <BrowserRouter>
      <Toast/>
      <Routes/>
    </BrowserRouter>
  );
};

export default App;
