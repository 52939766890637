import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Col, Row, Table } from "react-bootstrap-v5";
import { Redirect } from "react-router-dom";
import Api from "../../../api/Api";
import IconButton from "../../../components/common/buttons/icon-button/IconButton";
import LoadingBar from "../../../components/common/loading-bar/LoadingBar";
import ValidationSummary from "../../../components/common/validation-summary/ValidationSummary";
import { KIWANIS_BLUE_COLOR } from "../../../constants/Constants";
import ValidationErrors from "../../../helpers/validation-helper/ValidationErrors";
import Validations from "../../../helpers/validation-helper/Validations";
import styles from './ContestNotificationList.module.scss';
import { IContestNotificationListProps } from "./IContestNotificationListProps";
import { IContestNotificationListState } from "./IContestNotificationListState";
import withVotingAppAuthorize from "../../../components/common/authorize/withVotingAppAuthorize";
import { ISubscribeRequest } from "../../../api/api-interfaces/contest-notification-list/ISubscribeRequest";
import { IUnsubscribeRequest } from "../../../api/api-interfaces/contest-notification-list/IUnsubscribeRequest";

class ContestNotificationList extends Component<IContestNotificationListProps, IContestNotificationListState> {

  constructor(props: IContestNotificationListProps) {
    super(props);

    this.state = {
      contestId: 0,
      contestName: "",
      isContestActive: false,

      recipients: [],
      potentialRecipients: [],

      isLoading: true,
      validationErrors: null,
      excludeKeys: [],
      redirect: null
    };
  }

  render() {
    let {contestId} = this.state;

    if (this.state.redirect !== null) {
      return <Redirect push to={this.state.redirect}/>;
    }

    return (
      <>
        {
          this.state.validationErrors &&
          <Row>
            <Col md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}}>
              <ValidationSummary errors={this.state.validationErrors} excludeKeys={this.state.excludeKeys}/>
            </Col>
          </Row>
        }

        {
          this.state.isLoading ? <LoadingBar/> :
            <>
              <h1 className={"text-break headline"}>Submission notification list</h1>

              <Row>
                <Col md={6}>
                  <Table striped bordered responsive className={styles.columnWith}>
                    <thead>
                    <tr>
                      <th colSpan={6} className='text-center'>
                        People that receive notifications
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      this.state.recipients && this.state.recipients.length > 0
                        ? this.state.recipients.map((receiver) => {
                          return (
                            <tr key={receiver.contestRecipientId} className="text-break">
                              <td>
                                <span title="Receiver">
                                  <FontAwesomeIcon icon={faUser} color={KIWANIS_BLUE_COLOR}/>
                                  &nbsp;&nbsp;
                                  <span className='text-break'>{receiver.firstName}</span> &nbsp;
                                  <span className='text-break'>{receiver.lastName}</span> &nbsp;
                                  <span className='text-break'>({receiver.email})</span>
                                </span>
                              </td>
                              <td className={styles.columnButtonWidth}>
                                <IconButton iconType={'arrowRight'} buttonType={'button'} variant={'outline-primary'}
                                            size={"sm"} className="d-none d-md-block" disabled={this.state.isLoading}
                                            onClick={() => this.onRemoveFromNotificationList(receiver.contestRecipientId)}/>
                                <IconButton iconType={'down'} buttonType={'button'} variant={'outline-primary'}
                                            size={"sm"} className="d-block d-md-none" disabled={this.state.isLoading}
                                            onClick={() => this.onRemoveFromNotificationList(receiver.contestRecipientId)}/>
                              </td>
                            </tr>
                          );
                        })
                        : <tr>
                          <td colSpan={6} className={'text-center'}>No people that receive notifications</td>
                        </tr>
                    }
                    </tbody>
                  </Table>
                </Col>

                <Col md={6}>
                  <Table striped bordered responsive className={styles.columnWith}>
                    <thead>
                    <tr>
                      <th colSpan={6} className={'text-center'}>
                        People that can be added to notification list
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      this.state.potentialRecipients && this.state.potentialRecipients.length > 0
                        ? this.state.potentialRecipients.map((potentialRecipient, index) => {
                          return (
                            <tr key={index} className="text-break">
                              <td className={styles.columnButtonWidth}>
                                <IconButton iconType={'arrowLeft'} buttonType={'button'} variant={'outline-primary'}
                                            size={"sm"} className="d-none d-md-block" disabled={this.state.isLoading}
                                            onClick={() => this.onAddToNotificationList(contestId, potentialRecipient.userId)}/>

                                <IconButton iconType={'up'} buttonType={'button'} variant={'outline-primary'}
                                            size={"sm"} className="d-block d-md-none" disabled={this.state.isLoading}
                                            onClick={() => this.onAddToNotificationList(contestId, potentialRecipient.userId)}/>
                              </td>
                              <td>
                                <span title="Receiver">
                                  <FontAwesomeIcon icon={faUser} color={KIWANIS_BLUE_COLOR}/>
                                  &nbsp;&nbsp;
                                  <span className='text-break'>{potentialRecipient.firstName}</span> &nbsp;
                                  <span className='text-break'>{potentialRecipient.lastName}</span> &nbsp;
                                  <span className='text-break'>({potentialRecipient.email})</span>
                                </span>
                              </td>
                            </tr>
                          );
                        })
                        : <tr>
                          <td colSpan={6} className={'text-center'}>No people that can be added to notification list</td>
                        </tr>
                    }
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </>
        }
      </>
    );
  }

  async componentDidMount() {
    let contestId = this.props.contestId ?? 0;
    this.setState({contestId: contestId});

    await this.getSubmissionNotificationList(contestId);
  }

  private async getSubmissionNotificationList(contestId: number) {
    try {
      let response = await Api.getContestNotificationList(contestId);

      this.setState({
        recipients: response.recipients,
        potentialRecipients: response.potentialRecipients,

        validationErrors: {},
        isLoading: false
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get submission notification list of contest from the server', err)
      );
    }
  }

  private async onAddToNotificationList(contestId: number, userId: number) {
    this.setState({isLoading: true});

    let request: ISubscribeRequest = {
      contestId: contestId,
      userId: userId
    };

    try {
      let response = await Api.addToNotificationList(request);

      this.setState({
        potentialRecipients: response.potentialRecipients,
        recipients: response.recipients,

        validationErrors: {},
        isLoading: false
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors(
          'Can\'t subscribe user to contest entry submission notifications on the server',
          err
        )
      );
    }
  }

  private async onRemoveFromNotificationList(contestRecipientId: number) {
    this.setState({isLoading: true});

    let request: IUnsubscribeRequest = {
      contestRecipientId: contestRecipientId
    };

    try {
      let response = await Api.removeFromNotificationList(request);

      this.setState({
        potentialRecipients: response.potentialRecipients,
        recipients: response.recipients,

        validationErrors: {},
        isLoading: false
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors(
          'Can\'t unsubscribe user from contest entry submission notifications on the server',
          err
        )
      );
    }
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }
}

export default withVotingAppAuthorize(ContestNotificationList);