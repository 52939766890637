import React, { Component } from 'react';
import { Table } from "react-bootstrap-v5";
import { ICustomFieldInfo } from "../../../../api/api-interfaces/contest/ICustomFieldInfo";
import IconButton from "../../../../components/common/buttons/icon-button/IconButton";
import ValidationMessages from "../../../../components/common/validation-messages/ValidationMessages";
import { CUSTOM_FIELD_CODES } from "../../../../constants/Constants";
import FieldNameHelper from "../../../../helpers/field-name-helper/FieldNameHelper";
import AddCustomFieldModal from "./add-custom-field-modal/AddCustomFieldModal";
import styles from './CustomFieldsTable.module.scss';
import EditCustomFieldModal from "./edit-custom-field-modal/EditCustomFieldModal";
import { ICustomFieldsTableProps } from "./ICustomFieldsTableProps";
import { ICustomFieldsTableState } from "./ICustomFieldsTableState";

const DEFAULT: string = "DEFAULT";

class CustomFieldsTable extends Component<ICustomFieldsTableProps, ICustomFieldsTableState> {

  constructor(props: ICustomFieldsTableProps) {
    super(props);

    this.state = {
      isAddCustomFieldDialogOpen: false,
      isEditCustomFieldDialogOpen: false,
      indexToEdit: -1,
      customFieldToEdit: {
        id: 0,
        name: '',
        typeCode: DEFAULT,
        optionValue: '',
        options: null,
        description: '',
        showOnCode: DEFAULT,
        isRequired: false,
        order: 0
      }
    };
  }

  render() {
    return (
      <>
        <AddCustomFieldModal
          showDialog={this.state.isAddCustomFieldDialogOpen}
          customFieldTypes={this.props.customFieldTypes}
          customFieldShowOnValues={this.props.customFieldShowOnValues}
          onAddCustomFieldClick={(customField) => this.onAddCustomFieldClick(customField)}
          onCancel={this.onCancelAddCustomFieldClick.bind(this)}
        />

        <EditCustomFieldModal
          showDialog={this.state.isEditCustomFieldDialogOpen}
          customFieldTypes={this.props.customFieldTypes}
          customFieldShowOnValues={this.props.customFieldShowOnValues}
          onEditCustomFieldClick={(customField) => this.onEditCustomFieldClick(customField, this.state.indexToEdit)}
          onCancel={this.onCancelEditCustomFieldClick.bind(this)}
          customField={this.state.customFieldToEdit}
          indexToEdit={this.state.indexToEdit}
        />

        <h3>Contest entry fields</h3>

        <div className='my-2'>
          <IconButton variant={'primary'} title={'Add new field'} size={"sm"}
                      onClick={this.onAddCustomField.bind(this)}/>
        </div>

        <Table striped bordered responsive className={styles.columnWith}>
          <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Description</th>
            <th style={{maxWidth: '11rem'}}>Show on</th>
            <th className='text-center'>Required</th>
            <th className={`${styles.actionButton} text-center`}/>
          </tr>
          </thead>
          <tbody>
          {
            this.props.customFields && this.props.customFields.length > 0
              ? this.props.customFields.map((cf, index) =>
                <tr key={`cf_${index}`} className="text-break">
                  <td>
                    {cf.name}
                    <ValidationMessages fieldName={FieldNameHelper.getCustomFieldsFieldName(index)}
                                        errors={this.props.validationErrors ?? {}}/>
                    <ValidationMessages fieldName={FieldNameHelper.getNameFieldName(index)}
                                        errors={this.props.validationErrors ?? {}}/>
                  </td>
                  <td>
                    {this.findTypeLabel(cf.typeCode)}
                    <ValidationMessages fieldName={FieldNameHelper.getTypeCodeFieldName(index)}
                                        errors={this.props.validationErrors ?? {}}
                    />
                    {
                      cf.typeCode === CUSTOM_FIELD_CODES.RadioButton &&
                      <ul>
                        {
                          cf.options?.map((option, j) =>
                            <li key={`rb_${j}`}>{option.text}
                              <ValidationMessages fieldName={FieldNameHelper.getOptionsFieldName(index, j)}
                                                  errors={this.props.validationErrors ?? {}}/>
                            </li>
                          )
                        }
                      </ul>
                    }

                    {
                      cf.typeCode === CUSTOM_FIELD_CODES.DropDownList &&
                      <ul>
                        {
                          cf.options?.map((option, j) =>
                            <li key={`dr_${j}`}>{option.text}<br/>
                              <ValidationMessages fieldName={FieldNameHelper.getOptionsFieldName(index, j)}
                                                  errors={this.props.validationErrors ?? {}}/>
                            </li>
                          )
                        }
                      </ul>
                    }
                  </td>
                  <td>{cf.description}</td>
                  <td style={{maxWidth: '11rem'}}>
                    {this.findShowOnLabel(cf.showOnCode)}
                    <span>
                      <br/> <ValidationMessages fieldName={FieldNameHelper.getShowOnCodeFieldName(index)}
                                                errors={this.props.validationErrors ?? {}}/>
                    </span>
                  </td>
                  <td className='text-center'>{cf.isRequired ? 'Yes' : 'No'}</td>
                  <td className={`${styles.actionButton} text-center`}>
                    <span className="d-flex flex-wrap gap-1 justify-content-center">
                        <IconButton iconType={'up'}
                                    buttonType={'button'}
                                    variant={'outline-primary'}
                                    onClick={() => this.onUpCustomField(index)}
                                    size={"sm"}
                        />

                      <IconButton iconType={'down'}
                                  buttonType={'button'}
                                  variant={'outline-primary'}
                                  onClick={() => this.onDownCustomField(index)}
                                  size={"sm"}
                      />

                      <IconButton iconType={'edit'}
                                  buttonType={'button'}
                                  variant={'outline-primary'}
                                  onClick={() => this.onEditCustomField(cf, index)}
                                  size={"sm"}
                      />

                      <IconButton iconType={'delete'}
                                  buttonType={'button'}
                                  variant={'outline-danger'}
                                  onClick={() => this.onDeleteCustomField(index)}
                                  size={"sm"}
                      />
                    </span>
                  </td>
                </tr>
              ) : <tr>
                <td colSpan={6} className={'text-center'}>No custom fields</td>
              </tr>
          }
          </tbody>
        </Table>
      </>
    );
  }

  private onAddCustomField() {
    this.setState({isAddCustomFieldDialogOpen: true});
  }

  private onEditCustomField(customField: ICustomFieldInfo, index: number) {
    let state = {...this.state};
    state.customFieldToEdit = customField;
    state.indexToEdit = index;
    state.isEditCustomFieldDialogOpen = true;
    this.setState(state);
  }

  private onUpCustomField(indexToUp: number) {
    if (this.props.onUpCustomFieldClick) {
      this.props.onUpCustomFieldClick(indexToUp);
    }
  }

  private onDownCustomField(indexToDown: number) {
    if (this.props.onDownCustomFieldClick) {
      this.props.onDownCustomFieldClick(indexToDown);
    }
  }

  private onAddCustomFieldClick(customField: ICustomFieldInfo) {
    if (this.props.onAddCustomFieldClick) {
      this.props.onAddCustomFieldClick(customField);
    }

    this.setState({isAddCustomFieldDialogOpen: false, indexToEdit: -1});
  }

  private onEditCustomFieldClick(editedCustomField: ICustomFieldInfo, indexToEdit: number) {
    if (this.props.onEditCustomFieldClick) {
      this.props.onEditCustomFieldClick(editedCustomField, indexToEdit);
    }

    this.setState({isEditCustomFieldDialogOpen: false, indexToEdit: -1});
  }

  private onCancelAddCustomFieldClick() {
    this.closeAddVotesDialog();
  }

  private onCancelEditCustomFieldClick() {
    this.closeEditVotesDialog();
  }

  private onDeleteCustomField(indexToDelete: number) {
    if (this.props.onDeleteCustomFieldClick) {
      this.props.onDeleteCustomFieldClick(indexToDelete);
    }
  }

  private closeAddVotesDialog() {
    this.setState({isAddCustomFieldDialogOpen: false, indexToEdit: -1});
  }

  private closeEditVotesDialog() {
    this.setState({isEditCustomFieldDialogOpen: false, indexToEdit: -1});
  }

  private findTypeLabel(typeValue: string): string {
    let type = this.props.customFieldTypes.find(y => y.code === typeValue);
    if (type) {
      return type.name;
    } else return '';
  }

  private findShowOnLabel(showOnValue: string): string {
    let showOn = this.props.customFieldShowOnValues.find(y => y.code === showOnValue);
    if (showOn) {
      return showOn.name;
    } else return '';
  }
}

export default CustomFieldsTable;