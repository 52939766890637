import React, { Component } from "react";
import { Col, Form, InputGroup, Row, Table } from "react-bootstrap-v5";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../api/Api";
import { IAddJudgeRequest } from "../../api/api-interfaces/judges/IAddJudgeRequest";
import withVotingAppAuthorize from "../../components/common/authorize/withVotingAppAuthorize";
import IconButton from "../../components/common/buttons/icon-button/IconButton";
import CustomLink from "../../components/common/custom-link/CustomLink";
import LoadingBar from "../../components/common/loading-bar/LoadingBar";
import RequiredField from "../../components/common/required-field/RequiredField";
import ValidationMessages from "../../components/common/validation-messages/ValidationMessages";
import ValidationSummary from "../../components/common/validation-summary/ValidationSummary";
import { VerticalSpaceSize } from "../../components/common/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../components/common/vertical-space/VerticalSpace";
import { EMAIL_REGEX, USER_SORTING } from "../../constants/Constants";
import ValidationErrors from "../../helpers/validation-helper/ValidationErrors";
import Validations from "../../helpers/validation-helper/Validations";
import RoutingConstants from "../../routes/RoutingConstants";
import AddNewJudgeNotificationModal from "./add-new-juudge-notification-modal/AddNewJudgeNotificationModal";
import DeleteJudgeConfirmationModal from "./delete-judge-confirmation-modal/DeleteJudgeConfirmationModal";
import { IJudgesProps } from "./IJudgesProps";
import { IJudgesState } from "./IJudgesState";
import styles from './Judges.module.scss';

class Judges extends Component<IJudgesProps, IJudgesState> {
  constructor(props: IJudgesProps) {
    super(props);

    this.state = {
      contestId: 0,
      contestName: '',
      email: '',
      contestJudgeId: 0,
      judges: null,
      wasNewUserCreated: false,

      addNewJudge: false,

      isNotifyDialogOpen: false,
      dialogEmail: '',

      isDeleteDialogOpen: false,
      contestJudgeIdToDelete: undefined,
      judgeEmailToDelete: undefined,

      isLoading: true,
      validationErrors: null,
      excludeKeys: ['Email'],
      redirect: null,
    };

    this.onConfirmDeleteJudgeClick = this.onConfirmDeleteJudgeClick.bind(this);
    this.onCancelDeleteJudgeClick = this.onCancelDeleteJudgeClick.bind(this);
  }

  render() {
    if (this.state.redirect !== null) {
      let redirect = this.state.redirect;
      return <Redirect push to={redirect}/>;
    }

    return (
      <>
        {
          this.state.validationErrors &&
          <Row>
            <Col md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}}>
              <ValidationSummary errors={this.state.validationErrors} excludeKeys={this.state.excludeKeys}/>
            </Col>
          </Row>
        }

        {
          this.state.isLoading ? <LoadingBar/> :
            <>
              <AddNewJudgeNotificationModal
                showDialog={this.state.isNotifyDialogOpen}
                dialogEmail={this.state.dialogEmail}
                onConfirm={this.onConfirmNotifyClick.bind(this)}
              />

              <DeleteJudgeConfirmationModal
                isLoading={this.state.isLoading}
                showDialog={this.state.isDeleteDialogOpen}
                contestJudgeIdToDelete={this.state.contestJudgeIdToDelete}
                judgeEmailToDelete={this.state.judgeEmailToDelete}
                onConfirm={this.onConfirmDeleteJudgeClick}
                onCancel={this.onCancelDeleteJudgeClick}
              />

              <h1 className='headline text-break'>{this.state.contestName}</h1>

              <div className='mb-3'>
                <IconButton variant={'primary'} size={"sm"} title={'Add new judge'}
                            onClick={this.onAddNewJudge.bind(this)}
                />
              </div>

              {
                this.state.addNewJudge &&
                <form noValidate onSubmit={(e) => this.onSubmitJudgeEmail(e)}>
                  <Row>
                    <Col sm={8} md={6} lg={5} xxl={4}>
                      <Form.Label>Add judge by email address<RequiredField/></Form.Label>
                      <InputGroup>
                        <Form.Control id="judge-email" name="judge-email" type={'email'}
                                      placeholder="Please enter email"
                                      autoFocus={true}
                                      value={this.state.email}
                                      onChange={this.onEmailChange.bind(this)}
                        />
                        <IconButton title="&nbsp;Add&nbsp;" variant='outline-primary' buttonType={'submit'}/>
                      </InputGroup>
                      <ValidationMessages fieldName="Email" errors={this.state.validationErrors ?? {}}/>
                    </Col>
                  </Row>
                  <VerticalSpace size={VerticalSpaceSize.small}/>
                </form>
              }

              <Table striped bordered responsive className={styles.columnWith}>
                <thead>
                <tr>
                  <th>Email</th>
                  <th>First name</th>
                  <th>Last name</th>
                  <th className='text-center'/>
                </tr>
                </thead>
                <tbody>
                {
                  this.state.judges && this.state.judges.length > 0
                    ? this.state.judges.map(judge => {
                      return (
                        <tr id={`judge_${judge.contestJudgeId}`} key={judge.contestJudgeId}>
                          <td className='text-break'>{judge.email}</td>
                          <td className='text-break'>{judge.firstName}</td>
                          <td className='text-break'>{judge.lastName}</td>
                          <td className={styles.btnColumn}>
                            <span className="d-flex flex-wrap gap-1 justify-content-center">
                              <CustomLink to={
                                RoutingConstants.buildUpdateUserUrl(judge.userId, 1, USER_SORTING.LastName,
                                  RoutingConstants.buildJudgeListUrl(this.state.contestId))}
                              >
                                <IconButton iconType={'edit'} size={"sm"} variant={'outline-primary'}/>
                              </CustomLink>
                              <IconButton iconType={"delete"} size={"sm"} variant={"outline-danger"}
                                          onClick={() => this.onJudgeDeleteClick(judge.contestJudgeId, judge.email)}
                              />
                            </span>
                          </td>
                        </tr>
                      );
                    })
                    : <tr>
                      <td colSpan={6} className='text-center'>No judges</td>
                    </tr>
                }
                </tbody>
              </Table>
            </>
        }
      </>
    );
  }

  async componentDidMount() {
    let contestId = this.props.contestId;
    this.setState({contestId: contestId});

    await this.getJudgeList(contestId);
  }

  private async getJudgeList(contestId: number) {
    try {
      let response = await Api.getJudgeList(contestId);

      this.setState({
        contestName: response.contestName,
        judges: response.judges,
        isLoading: false,
        validationErrors: {}
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get judge list from the server', err)
      );
    }
  }

  private onAddNewJudge() {
    this.setState({addNewJudge: true});
  }

  private onEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    let errors = Validations.deleteErrors(this.state.validationErrors ?? {}, 'Email');
    this.setState({email: e.target.value, validationErrors: errors});
  }

  private async onSubmitJudgeEmail(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    let errors = this.validateForm();
    if (Object.keys(errors).length > 0) {
      this.setState({validationErrors: errors});
      return;
    }

    this.setState({isLoading: true});

    let email = this.state.email;
    let request: IAddJudgeRequest = {
      contestId: this.state.contestId,
      email: email
    };

    try {
      let response = await Api.addJudgeToContest(request);

      this.setState({
        judges: response.judges,
        wasNewUserCreated: response.wasNewUserCreated,
        isLoading: false,
        validationErrors: {},
        addNewJudge: false,
        email: ''
      });

      if (response.wasNewUserCreated) {
        this.setState({isNotifyDialogOpen: true, dialogEmail: email});
      } else {
        Judges.showSuccessToastMessage();
      }
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t add judge to a contest on the server', err)
      );
    }
  }

  private validateForm(): ValidationErrors {
    let errors: ValidationErrors = {};

    if (!EMAIL_REGEX.test(this.state.email)) {
      errors = Validations.setErrors({...errors}, 'Email', ['Email is invalid.']);
    }

    if (!this.state.email || this.state.email.trim().length < 1) {
      errors = Validations.setErrors({...errors}, 'Email', ['Email is required.']);
    }

    return errors;
  }

  private onConfirmNotifyClick() {
    let state = {...this.state};
    state.isNotifyDialogOpen = false;
    this.setState(state);
  }

  private onJudgeDeleteClick(judgeId: number, judgeEmail: string) {
    let state = {...this.state};
    state.contestJudgeIdToDelete = judgeId;
    state.judgeEmailToDelete = judgeEmail;
    state.isDeleteDialogOpen = true;
    this.setState(state);
  }

  private async onConfirmDeleteJudgeClick(judgeId: number) {
    this.setState({isLoading: true});

    try {
      let response = await Api.deleteJudge(this.state.contestId, judgeId);

      this.setState({
        judges: response.judges,
        isLoading: false,
        validationErrors: {}
      });
    } catch (err) {
      this.setValidationErrors(Validations.buildApiCommunicationErrors('Can\'t delete judge from a contest', err));
      this.closeDeleteJudgeDialog();
      return;
    }

    this.closeDeleteJudgeDialog();
  }

  private onCancelDeleteJudgeClick() {
    this.closeDeleteJudgeDialog();
  }

  private closeDeleteJudgeDialog() {
    let state = {...this.state};
    state.contestJudgeIdToDelete = undefined;
    state.judgeEmailToDelete = undefined;
    state.isDeleteDialogOpen = false;
    this.setState(state);
  }

  private static showSuccessToastMessage() {
    toast.success('Judge has been added');
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }
}

export default withVotingAppAuthorize(Judges);