import React, { Component } from 'react';
import ReactModal from 'react-modal';
import IconButton from "../../../components/common/buttons/icon-button/IconButton";
import styles from '../../../styles/ModalWindow.module.scss';
import { IDeleteJudgeConfirmationModalProps } from './IDeleteJudgeConfirmationModalProps';
import { IDeleteJudgeConfirmationModalState } from './IDeleteJudgeConfirmationModalState';

class DeleteJudgeConfirmationModal extends Component<IDeleteJudgeConfirmationModalProps, IDeleteJudgeConfirmationModalState> {

  private static removeDialogIndex = 100;

  constructor(props: IDeleteJudgeConfirmationModalProps) {
    super(props);

    this.state = {
      dialogId: 'delete_judge_dialog_' + (DeleteJudgeConfirmationModal.removeDialogIndex++)
    };
  }

  render() {
    const {showDialog} = this.props;

    return (
      <ReactModal id={this.state.dialogId}
                  isOpen={showDialog}
                  onRequestClose={this.onCancel.bind(this)}
                  shouldCloseOnOverlayClick={false}
                  shouldCloseOnEsc={false}
                  className={`${styles.content} shadow p-4`}
                  overlayClassName={`${styles.overlay}`}
                  appElement={document.getElementById('root') as HTMLElement}>

        <h2>Delete judge</h2>

        <p>Are you sure you want to delete judge <b className={"text-break"}>{this.props.judgeEmailToDelete}</b>?</p>

        <div className="d-flex justify-content-end">
          <IconButton onClick={this.onConfirmClicked.bind(this)} variant="outline-danger" title="Delete"
                      styles={{marginRight: '1em'}} disabled={this.props.isLoading}/>
          <IconButton onClick={this.onCancel.bind(this)} variant="outline-secondary" title="Cancel"/>
        </div>
      </ReactModal>
    );
  }

  private onConfirmClicked() {
    if (this.props.onConfirm) {
      this.props.onConfirm(this.props.contestJudgeIdToDelete ?? 0);
    }
  }

  private onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }
}

export default DeleteJudgeConfirmationModal;