import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { KIWANIS_BACKGROUND_TEXT_COLOR, KIWANIS_GOLD_COLOR } from "../../../constants/Constants";
import { ICustomLinkProps } from "./ICustomLinkProps";

class CustomLink extends Component<ICustomLinkProps> {
  render() {
    let styles = this.props.styles;
    let color = this.props.isActive === undefined
      ? `${KIWANIS_GOLD_COLOR}`
      : this.props.isActive
        ? `${KIWANIS_GOLD_COLOR}`
        : `${KIWANIS_BACKGROUND_TEXT_COLOR}`;

    return (
      <Link to={this.props.to}
            style={{...styles, color: color}}
            onClick={this.onClickHandler.bind(this)}>
        {this.props.children}
      </Link>
    );
  }

  private onClickHandler() {
    if (this.props.onClick) {
      this.props.onClick(this.props.value);
    }
  }
}

export default CustomLink;