import Axios, { AxiosInstance } from "axios";
import urljoin from "url-join";
import RoutingConstants from "../routes/RoutingConstants";
import { ICheckVotingEndDateRequest } from "./api-interfaces/contest/ICheckVotingEndDateRequest";
import { ICheckVotingEndDateResponse } from "./api-interfaces/contest/ICheckVotingEndDateResponse";
import { IGetContestListRequest } from "./api-interfaces/contest/IGetContestListRequest";
import { IGetContestListResponse } from "./api-interfaces/contest/IGetContestListResponse";
import { IGetEditDetailsResponse } from "./api-interfaces/contest/IGetEditDetailsResponse";
import { IGetOptionsResponse } from "./api-interfaces/contest/IGetOptionsResponse";
import { ISaveContestRequest } from "./api-interfaces/contest/ISaveContestRequest";
import { ISaveContestResponse } from "./api-interfaces/contest/ISaveContestResponse";
import {
  IAddManualVotesFromEntryDetailsRequest
} from "./api-interfaces/entry/entry-details/IAddManualVotesFromEntryDetailsRequest";
import { IApproveEntryRequest } from "./api-interfaces/entry/entry-details/IApproveEntryRequest";
import { IDetailsResponse } from "./api-interfaces/entry/entry-details/IDetailsResponse";
import { IUnApproveEntryRequest } from "./api-interfaces/entry/entry-details/IUnApproveEntryRequest";
import { IDeleteEntryResponse } from "./api-interfaces/entry/entry-list/IDeleteEntryResponse";
import { IEntriesForPdfRequest } from "./api-interfaces/entry/entry-list/IEntriesForPdfRequest";
import { IEntriesForPdfResponse } from "./api-interfaces/entry/entry-list/IEntriesForPdfResponse";
import { IGetContestAllEntryIdsResponse } from "./api-interfaces/entry/entry-list/IGetContestAllEntryIdsResponse";
import { IAddManualVotesRequest } from "./api-interfaces/entry/IAddManualVotesRequest";
import { IAddManualVotesResponse } from "./api-interfaces/entry/IAddManualVotesResponse";
import { IExportToExcelRequest } from "./api-interfaces/entry/entry-list/IExportToExcelRequest";
import { IExportToExcelResponse } from "./api-interfaces/entry/entry-list/IExportToExcelResponse";
import { IDeleteEntryRequest } from "./api-interfaces/entry/IDeleteEntryRequest";
import { IEntriesRequest } from "./api-interfaces/entry/IEntriesRequest";
import { IEntriesResponse } from "./api-interfaces/entry/IEntriesResponse";
import { IGetDetailsForCreationResponse } from "./api-interfaces/entry/IGetDetailsForCreationResponse";
import { IGetDetailsForEditingResponse } from "./api-interfaces/entry/IGetDetailsForEditingResponse";
import { ISaveRequest } from "./api-interfaces/entry/ISaveRequest";
import { ISaveResponse } from "./api-interfaces/entry/ISaveResponse";
import {
  IAddVotesManuallyFromVoteListRequest
} from "./api-interfaces/entry/vote-list/IAddVotesManuallyFromVoteListRequest";
import { IDeleteVoteRequest } from "./api-interfaces/entry/vote-list/IDeleteVoteRequest";
import { IExportVotesToExcelRequest } from "./api-interfaces/entry/vote-list/IExportVotesToExcelRequest";
import { IExportVotesToExcelResponse } from "./api-interfaces/entry/vote-list/IExportVotesToExcelResponse";
import { IGetVotesRequest } from "./api-interfaces/entry/vote-list/IGetVotesRequest";
import { IGetVotesResponse } from "./api-interfaces/entry/vote-list/IGetVotesResponse";
import { IPageOfVotesWithTotalNumber } from "./api-interfaces/entry/vote-list/IPageOfVotesWithTotalNumber";
import ApiCommunicationError from "./api-interfaces/errors/ApiCommunicationError";
import BadRequestError from "./api-interfaces/errors/BadRequestError";
import NotFoundError from "./api-interfaces/errors/NotFoundError";
import { IAddJudgeRequest } from "./api-interfaces/judges/IAddJudgeRequest";
import { IAddJudgeResponse } from "./api-interfaces/judges/IAddJudgeResponse";
import { IGetJudgeListResponse } from "./api-interfaces/judges/IGetJudgeListResponse";
import { IGetSignInDetailsResponse } from "./api-interfaces/login/IGetSignInDetailsResponse";
import { IGetTokenRequest } from "./api-interfaces/login/IGetTokenRequest";
import { IGetTokenResponse } from "./api-interfaces/login/IGetTokenResponse";
import { ISendSignInCodeRequest } from "./api-interfaces/login/ISendSignInCodeRequest";
import { IGetUserForSavingResponse } from "./api-interfaces/users/IGetUserForSavingResponse";
import { IGetUserListRequest } from "./api-interfaces/users/IGetUserListRequest";
import { IGetUserListResponse } from "./api-interfaces/users/IGetUserListResponse";
import { ISaveUserRequest } from "./api-interfaces/users/ISaveUserRequest";
import { ISaveUserResponse } from "./api-interfaces/users/ISaveUserResponse";
import { IGetContestAllEntryIdsRequest } from "./api-interfaces/entry/entry-list/IGetContestAllEntryIdsRequest";
import { IDeleteJudgeResponse } from "./api-interfaces/judges/IDeleteJudgeResponse";
import {
  IGetContestNotificationListResponse
} from "./api-interfaces/contest-notification-list/IGetContestNotificationListResponse";
import { ISubscribeRequest } from "./api-interfaces/contest-notification-list/ISubscribeRequest";
import { ISubscribeResponse } from "./api-interfaces/contest-notification-list/ISubscribeResponse";
import { IUnsubscribeRequest } from "./api-interfaces/contest-notification-list/IUnsubscribeRequest";
import { IUnsubscribeDetailsResponse } from "./api-interfaces/unsubscribe/IUnsubscribeDetailsResponse";
import AuthHelper from "../helpers/auth-helper/AuthHelper";
import { IUnsubscribeFromEmailRequest } from "./api-interfaces/unsubscribe/IUnsubscribeFromEmailRequest";
import { ICopyContestRequest } from "./api-interfaces/entry/ICopyContestRequest";
import { ICopyContestResponse } from "./api-interfaces/entry/ICopyContestResponse";

export default class Api {
  public static async getSignInDetails(): Promise<IGetSignInDetailsResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildApiPublicUrl('/api/Login/GetSignInDetails');

    try {
      let response = await client.get<IGetSignInDetailsResponse>(url);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get get sign in details');
    }
  }

  public static async sendSignInCode(data: ISendSignInCodeRequest): Promise<void> {
    let url = Api.buildApiPublicUrl('/api/Login/SendSignInCode');

    try {
      await Axios.post<void>(url, data);
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t send sign in code');
    }
  }

  public static async getToken(data: IGetTokenRequest): Promise<IGetTokenResponse> {
    let url = Api.buildApiPublicUrl('/api/Login/GetToken');

    try {
      let response = await Axios.post<IGetTokenResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get authentication token');
    }
  }

  public static async getContestOptions(): Promise<IGetOptionsResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/ContestSave/GetOptions');

    try {
      let response = await client.get<IGetOptionsResponse>(url);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get contest options');
    }
  }

  public static async getContestEditDetails(contestId: number): Promise<IGetEditDetailsResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl(`/api/ContestSave/GetEditDetails?contestId=${contestId}`);

    try {
      let response = await client.get<IGetEditDetailsResponse>(url);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get contest edit details');
    }
  }

  public static async getContests(data: IGetContestListRequest): Promise<IGetContestListResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/ContestList/GetContestList');

    try {
      let response = await client.post<IGetContestListResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get contest list');
    }
  }

  public static async checkVotingEndDate(data: ICheckVotingEndDateRequest): Promise<ICheckVotingEndDateResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/ContestSave/CheckVotingEndDate');

    try {
      let response = await client.post<ICheckVotingEndDateResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t check voting end date');
    }
  }

  public static async saveContest(data: ISaveContestRequest): Promise<ISaveContestResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/ContestSave/SaveContest');

    try {
      let response = await client.post<ISaveContestResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t save contest');
    }
  }

  public static async deleteContest(contestId: number): Promise<void> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl(`/api/ContestList/Delete?contestId=${contestId}`);

    try {
      await client.delete<void>(url);
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t delete contest');
    }
  }

  public static async copyContestFromEntryList(data: ICopyContestRequest): Promise<ICopyContestResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/ContestEntryList/CopyContest/');

    try {
      let response =  await client.post<ICopyContestResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t copy contest');
    }
  }

  public static async deleteContestFromEntryList(contestId: number): Promise<void> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/ContestEntryList/DeleteContest/' + contestId);

    try {
      await client.delete<void>(url);
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t delete contest');
    }
  }

  public static async deactivateContestFromEntryList(contestId: number): Promise<void> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/ContestEntryList/DeactivateContest/' + contestId);

    try {
      await client.post<void>(url);
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t deactivate contest');
    }
  }

  public static async activateContestFromEntryList(contestId: number): Promise<void> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/ContestEntryList/ActivateContest/' + contestId);

    try {
      await client.post<void>(url);
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t activate contest');
    }
  }

  public static async exportEntriesToExcel(data: IExportToExcelRequest): Promise<IExportToExcelResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/ContestEntryList/ExportToExcel');

    try {
      let response = await client.post<IExportToExcelResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t export entries in xlsx format');
    }
  }

  public static async getContestEntryList(data: IEntriesRequest): Promise<IEntriesResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/ContestEntryList/Entries');

    try {
      let response = await client.post<IEntriesResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get list of contest entries');
    }
  }

  public static async addVotesFromEntryList(data: IAddManualVotesRequest): Promise<IAddManualVotesResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/ContestEntryList/AddManualVotes');

    try {
      let response = await client.post<IAddManualVotesResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t add manual votes');
    }
  }

  public static async saveContestEntry(data: ISaveRequest): Promise<ISaveResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/ContestEntrySave/Save');

    try {
      let response = await client.post<ISaveResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t save contest entry');
    }
  }

  public static async deleteEntryFromEntryList(data: IDeleteEntryRequest): Promise<IDeleteEntryResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl(`/api/ContestEntryList/DeleteEntry`);

    try {
      let response = await client.post<IDeleteEntryResponse>(url, data);
      return response.data
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t delete contest entry');
    }
  }

  public static async getEntriesForPdfExport(data: IEntriesForPdfRequest): Promise<IEntriesForPdfResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/ContestEntryList/EntriesForPdf');

    try {
      let response = await client.post<IEntriesForPdfResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get entries for PDF export');
    }
  }

  public static async getContestAllEntryIds(data: IGetContestAllEntryIdsRequest): Promise<IGetContestAllEntryIdsResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl(`/api/ContestEntryList/GetContestAllEntryIds`);

    try {
      let response = await client.post<IGetContestAllEntryIdsResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get list of all entry IDs in contest');
    }
  }

  public static async getDetailsForCreation(contestId: number): Promise<IGetDetailsForCreationResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl(`/api/ContestEntrySave/GetDetailsForCreation?contestId=${contestId}`);

    try {
      let response = await client.get<IGetDetailsForCreationResponse>(url);

      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get details for creation');
    }
  }

  public static async getDetailsForEditing(entryId: number): Promise<IGetDetailsForEditingResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl(`/api/ContestEntrySave/GetDetailsForEditing?entryId=${entryId}`);

    try {
      let response = await client.get<IGetDetailsForEditingResponse>(url);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get details for editing');
    }
  }

  public static async getEntryDetails(entryId: number): Promise<IDetailsResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl(`/api/ContestEntryDetails/Details/${entryId}`);

    try {
      let response = await client.get<IDetailsResponse>(url);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get contest entry details');
    }
  }

  public static async addVotesFromEntryDetails(data: IAddManualVotesFromEntryDetailsRequest): Promise<IDetailsResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/ContestEntryDetails/AddManualVotes');

    try {
      let response = await client.post<IDetailsResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t add manual votes');
    }
  }

  public static async approveEntry(data: IApproveEntryRequest): Promise<IDetailsResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/ContestEntryDetails/ApproveEntry');

    try {
      let response = await client.post<IDetailsResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t approve contest entry');
    }
  }

  public static async unApproveEntry(data: IUnApproveEntryRequest): Promise<IDetailsResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/ContestEntryDetails/UnApproveEntry');

    try {
      let response = await client.post<IDetailsResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t un-approve contest entry');
    }
  }

  public static async deleteEntryFromEntryDetails(entryId: number): Promise<void> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl(`/api/ContestEntryDetails/Delete/${entryId}`);

    try {
      await client.delete<void>(url);
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t delete contest entry');
    }
  }

  public static async getEntryVoteList(data: IGetVotesRequest): Promise<IGetVotesResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/VoteList/GetVotes');

    try {
      let response = await client.post<IGetVotesResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get list of votes');
    }
  }

  public static async exportVotesToExcel(data: IExportVotesToExcelRequest): Promise<IExportVotesToExcelResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/VoteList/ExportVotesToExcel');

    try {
      let response = await client.post<IExportVotesToExcelResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t export votes to xlsx format');
    }
  }

  public static async addVotesFromVoteList(data: IAddVotesManuallyFromVoteListRequest): Promise<IPageOfVotesWithTotalNumber> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/VoteList/AddVotesManually');

    try {
      let response = await client.post<IPageOfVotesWithTotalNumber>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t add votes manually');
    }
  }

  public static async deleteVote(data: IDeleteVoteRequest): Promise<IPageOfVotesWithTotalNumber> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/VoteList/DeleteVote');

    try {
      let response = await client.post<IPageOfVotesWithTotalNumber>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t delete vote');
    }
  }

  public static async getUsers(data: IGetUserListRequest): Promise<IGetUserListResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl(`/api/UsersList/Users`);

    try {
      let response = await client.post<IGetUserListResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get user list');
    }
  }

  public static async getUserDetailsForSaving(userId: number): Promise<IGetUserForSavingResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl(`/api/UserSave/GetUserForSaving?userId=${userId}`);

    try {
      let response = await client.get<IGetUserForSavingResponse>(url);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get user details for saving');
    }
  }

  public static async saveUser(data: ISaveUserRequest): Promise<ISaveUserResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl('/api/UserSave/Save');

    try {
      let response = await client.put<ISaveUserResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t save user');
    }
  }

  public static async deleteUser(userId: number): Promise<void> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl(`/api/UsersList/Delete?userId=${userId}`);

    try {
      await client.delete<void>(url);
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t delete user');
    }
  }

  public static async getJudgeList(contestId: number): Promise<IGetJudgeListResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl(`/api/ContestJudgeList/List/${contestId}`);

    try {
      let response = await client.get<IGetJudgeListResponse>(url);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get judge list');
    }
  }

  public static async addJudgeToContest(data: IAddJudgeRequest): Promise<IAddJudgeResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl(`/api/ContestJudgeList/Add`);

    try {
      let response = await client.post<IAddJudgeResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t add judge to a contest');
    }
  }

  public static async deleteJudge(contestId: number, judgeId: number): Promise<IDeleteJudgeResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl(`/api/ContestJudgeList/Delete/${contestId}/${judgeId}`);

    try {
      let response = await client.delete<IDeleteJudgeResponse>(url);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t delete judge from a contest');
    }
  }

  public static async getContestNotificationList(contestId: number): Promise<IGetContestNotificationListResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl(`/api/ContestNotificationList/List/${contestId}`);

    try {
      let response = await client.get<IGetContestNotificationListResponse>(url);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get contest notification list');
    }
  }

  public static async addToNotificationList(data: ISubscribeRequest): Promise<ISubscribeResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl(`/api/ContestNotificationList/Subscribe`);

    try {
      let response = await client.post<ISubscribeResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(
        err,
        'Can\'t subscribe user to contest entry submission notifications'
      );
    }
  }

  public static async removeFromNotificationList(data: IUnsubscribeRequest): Promise<IGetContestNotificationListResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl(`/api/ContestNotificationList/Unsubscribe`);

    try {
      let response = await client.post<IGetContestNotificationListResponse>(url, data);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(
        err,
        'Can\'t unsubscribe user from contest entry submission notifications');
    }
  }

  public static async getUnsubscribeDetails(subscriptionId: string): Promise<IUnsubscribeDetailsResponse> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl(`/api/Unsubscribe/Details/?subscriptionId=${subscriptionId}`);

    try {
      let response = await client.get<IUnsubscribeDetailsResponse>(url);
      return response.data;
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t get unsubscribe details');
    }
  }

  public static async Unsubscribe(data: IUnsubscribeFromEmailRequest): Promise<void> {
    let client = Api.getAuthenticatedAxiosClient();
    let url = Api.buildUrl(`/api/Unsubscribe/Unsubscribe`);

    try {
      await client.post<void>(url, data);
    } catch (err) {
      throw Api.buildCommunicationException(err, 'Can\'t unsubscribe user from contest entry submission notifications');
    }
  }

  private static buildCommunicationException(err: any, generalErrorMessage: string): Error {
    if (err.response) {
      if (err.response.status === 400) {
        let content = err.response.data;
        throw new BadRequestError(content);
      }
      if (err.response.status === 401) {
        window.location.pathname = RoutingConstants.LOGIN;
      }
      if (err.response.status === 403) {
        window.location.pathname = RoutingConstants.NO_PERMITTED;
      }
      if (err.response.status === 404) {
        throw new NotFoundError(err);
      }
    }
    throw new ApiCommunicationError(generalErrorMessage, err);
  }

  private static buildApiPublicUrl(relativePath: string): string {
    const apiUrl = (window as any).VotingAppSettings.API_PUBLIC_URL;
    return urljoin(apiUrl, relativePath);
  }

  private static buildUrl(relativePath: string): string {
    const apiUrl = (window as any).VotingAppSettings.API_ADMIN_URL;
    return urljoin(apiUrl, relativePath);
  }

  private static getAuthenticatedAxiosClient(): AxiosInstance {
    let accessToken = AuthHelper.getAccessToken() ?? '';

    return Axios.create({
      headers: {
        Authorization: 'Bearer ' + accessToken,
        Accept: 'application/json'
      },
    });
  }
}