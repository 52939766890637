import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';
import { IImageGalleryProps } from "./IImageGalleryProps";
import { IImageGalleryState } from "./IImageGalleryState";
import './ImageGallery.scss';

class ImageGallery extends Component<IImageGalleryProps, IImageGalleryState> {
  constructor(props: IImageGalleryProps) {
    super(props);

    this.state = {};

    this.onCloseRequest = this.onCloseRequest.bind(this);
    this.onMovePrevRequest = this.onMovePrevRequest.bind(this);
    this.onMoveNextRequest = this.onMoveNextRequest.bind(this);
  }

  render() {
    const customStyles = {
      overlay: {
        zIndex: 1020
      }
    };
    const {images, isOpen, indexOfImages} = this.props;

    return (
      <>
        {
          isOpen && (
            <Lightbox reactModalStyle={customStyles}
                      mainSrc={images[indexOfImages]}
                      nextSrc={images[(indexOfImages + 1) % images.length]}
                      prevSrc={images[(indexOfImages + images.length - 1) % images.length]}

                      onCloseRequest={this.onCloseRequest}

                      onMovePrevRequest={() =>
                        this.onMovePrevRequest((indexOfImages + images.length - 1) % images.length)
                      }

                      onMoveNextRequest={() =>
                        this.onMoveNextRequest((indexOfImages + images.length + 1) % images.length)
                      }
            />
          )
        }
      </>
    );
  }

  onCloseRequest() {
    if (this.props.onCloseRequest !== undefined && this.props.onCloseRequest !== null) {
      this.props.onCloseRequest(false);
    }
  }

  onMovePrevRequest(indexOfImages: number) {
    if (this.props.onMovePrevRequest !== undefined && this.props.onMovePrevRequest !== null) {
      this.props.onMovePrevRequest(indexOfImages);
    }
  }

  onMoveNextRequest(indexOfImages: number) {
    if (this.props.onMoveNextRequest !== undefined && this.props.onMoveNextRequest !== null) {
      this.props.onMoveNextRequest(indexOfImages);
    }
  }
}

export default ImageGallery;