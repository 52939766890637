import React, { Component } from 'react';
import { IReCaptchaProps } from "./IReCaptchaProps";
import ReCAPTCHA from "react-google-recaptcha";

class ReCaptcha extends Component<IReCaptchaProps> {
  private recaptchaRef = React.createRef<ReCAPTCHA>();

  render() {
    return (
      <ReCAPTCHA ref={this.recaptchaRef}
                 sitekey={this.props.recaptchaSiteKey}
                 size={"invisible"}
      />
    );
  }

  public async getToken(): Promise<string | null> {
    const token = await this.recaptchaRef.current?.executeAsync();

    return token === undefined ? null : token;
  }

  public async reset(): Promise<void> {
    await this.recaptchaRef.current?.reset();
  }
}

export default ReCaptcha;