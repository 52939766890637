export const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#A9A8A9',
  borderStyle: 'dashed',
  backgroundColor: '#ffffff',
  outline: 'none'
};

export const activeStyle = {
  borderColor: '#003874'
};

export const acceptStyle = {
  borderColor: '#00cf6a'
};

export const rejectStyle = {
  borderColor: '#ff1744'
};

export const deleteFileIcon: any = {
  position: 'absolute',
  top: 0,
  right: 1,
  backgroundColor: 'white',
  borderRadius: 2
};

export const mainPhotoStyle: any = {
  position: 'absolute',
  top: 0,
  left: 1,
  backgroundColor: 'white',
  borderRadius: 50
};

export const thumbsContainer: any = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
};

export const thumb: any = {
  position: 'relative',
  borderRadius: '2px',
  border: '1px solid #A9A8A9',
  marginBottom: '8px',
  marginRight: '8px',
  width: '90px',
  padding: '4px',
  boxSizing: 'border-box'
};

export const thumbInner: any = {
  minWidth: '50px',
  position: 'relative',
  paddingTop: '56.25%' /* 16:9 Aspect Ratio */
};

export const img: any = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover'
};