import React, { Component } from 'react';
import {
  CUSTOM_FIELDS_SHOW_ON_CODES,
  KIWANIS_BACKGROUND_COLOR,
  KIWANIS_BACKGROUND_TEXT_COLOR,
  KIWANIS_GOLD_COLOR
} from "../../../../../../constants/Constants";
import { IStaffOnlyProps } from "./IStaffOnlyProps";

class StaffOnly extends Component<IStaffOnlyProps> {
  render() {
    let {showOnCode} = this.props;

    return (
      <>
        {
          showOnCode === CUSTOM_FIELDS_SHOW_ON_CODES.StaffOnly &&
          <div className={'text-break rounded'} style={{background: KIWANIS_BACKGROUND_COLOR}}>
              <span style={{color: KIWANIS_GOLD_COLOR}}>
                 &nbsp; &#8505; &nbsp;
              </span>
              <span style={{color: KIWANIS_BACKGROUND_TEXT_COLOR}}>
                This field is visible only to staff. Submitter or voters do not see it.
              </span>
          </div>
        }
      </>
    );
  }

}

export default StaffOnly;