import React, { FC } from "react";
import { Redirect, Route, Switch } from 'react-router-dom';
import Authorize from "../components/common/authorize/Authorize";
import AddOrEditContest from "../pages/contests/add-or-edit-contest/AddOrEditContest";
import ContestList from "../pages/contests/contest-list/ContestList";
import AddOrEditEntry from "../pages/contests/entries/add-or-edit-entry/AddOrEditEntry";
import EntryDetails from "../pages/contests/entries/entry-details/EntryDetails";
import EntryList from "../pages/contests/entries/entry-list/EntryList";
import VoteList from "../pages/contests/entries/vote-list/VoteList";
import Judges from "../pages/judges/Judges";
import Login from "../pages/login/Login";
import NoPermitted from "../pages/no-permitted/NoPermitted";
import NotFound from "../pages/not-found/NotFound";
import AddOrEditUser from "../pages/users/add-or-edit-user/AddOrEditUser";
import Users from "../pages/users/Users";
import RoutingConstants from "./RoutingConstants";
import ContestNotificationList from "../pages/contests/contest-notification-list/ContestNotificationList";
import Unsubscribe from "../pages/unsubscribe/Unsubscribe";
import MasterLayout from "../components/layout/MasterLayout";
import UnauthorizedLayout from "../components/layout/UnauthorizedLayout";

const Routes: FC = () => {
  return (
    <Switch>
      <Route path={RoutingConstants.LOGIN}
             render={
               () => <MasterLayout children={
                 <Login/>
               }/>
             }
      />

      <Route exact path={RoutingConstants.HOME}>
        <Redirect push to={RoutingConstants.buildContestUrl(1, 0)}/>
      </Route>

      <Route exact path={RoutingConstants.CONTESTS} render={
        ({match}) => (
          <Authorize>
            <MasterLayout children={
              <ContestList page={match.params.page ? parseInt(match.params.page) : 1}
                           contestId={match.params.contestId ? parseInt(match.params.contestId) : 0}
              />
            }/>
          </Authorize>
        )}
      />

      <Route exact path={RoutingConstants.CREATE_CONTEST} render={
        ({match}) => (
          <Authorize>
            <MasterLayout children={
              <AddOrEditContest contestId={match.params.contestId ? parseInt(match.params.contestId) : null}/>
            }/>
          </Authorize>
        )}
      />

      <Route exact path={RoutingConstants.EDIT_CONTEST} render={
        ({match}) => (
          <Authorize>
            <MasterLayout children={
              <AddOrEditContest contestId={match.params.contestId ? parseInt(match.params.contestId) : null}/>
            }/>
          </Authorize>
        )
      }/>

      <Route exact path={RoutingConstants.CONTEST_ENTRIES} render={
        ({match}) => (
          <Authorize>
            <MasterLayout children={
              <EntryList contestId={match.params.contestId ? parseInt(match.params.contestId) : null}/>
            }/>
          </Authorize>
        )}
      />

      <Route exact path={RoutingConstants.CREATE_ENTRY} render={
        ({match}) => (
          <Authorize>
            <MasterLayout children={
              <AddOrEditEntry contestId={match.params.contestId ? parseInt(match.params.contestId) : null}
                              entryId={match.params.entryId ? parseInt(match.params.entryId) : null}
              />
            }/>
          </Authorize>
        )}
      />

      <Route exact path={RoutingConstants.EDIT_ENTRY} render={
        ({match}) => (
          <Authorize>
            <MasterLayout children={
              <AddOrEditEntry contestId={match.params.contestId ? parseInt(match.params.contestId) : null}
                              entryId={match.params.entryId ? parseInt(match.params.entryId) : null}
              />
            }/>
          </Authorize>
        )}
      />

      <Route exact path={RoutingConstants.ENTRY_DETAILS} render={
        ({match}) => (
          <Authorize>
            <MasterLayout children={
              <EntryDetails contestId={match.params.contestId ? parseInt(match.params.contestId) : null}
                            entryId={match.params.entryId ? parseInt(match.params.entryId) : null}
              />
            }/>
          </Authorize>
        )}
      />

      <Route exact path={RoutingConstants.ENTRY_VOTE_LIST} render={
        ({match}) => (
          <Authorize>
            <MasterLayout children={
              <VoteList entryId={match.params.entryId ? parseInt(match.params.entryId) : null}/>
            }/>
          </Authorize>
        )}
      />

      <Route exact path={RoutingConstants.USERS} render={
        ({match}) => (
          <Authorize>
            <MasterLayout children={
              <Users userId={match.params.userId ? parseInt(match.params.userId) : 0}
                     page={match.params.page ? parseInt(match.params.page) : 1}
              />
            }/>
          </Authorize>
        )}
      />

      <Route exact path={RoutingConstants.CREATE_USER} render={
        ({match}) => (
          <Authorize>
            <MasterLayout children={
              <AddOrEditUser userId={match.params.userId}/>
            }/>
          </Authorize>
        )}
      />

      <Route exact path={RoutingConstants.UPDATE_USER} render={
        ({match}) => (
          <Authorize>
            <MasterLayout children={
              <AddOrEditUser userId={match.params.userId}/>
            }/>
          </Authorize>
        )}
      />

      <Route exact path={RoutingConstants.JUDGES} render={
        ({match}) => (
          <Authorize>
            <MasterLayout children={
              <Judges contestId={match.params.contestId ? parseInt(match.params.contestId) : 0}/>
            }/>
          </Authorize>
        )}
      />

      <Route exact path={RoutingConstants.NOTIFICATION_LIST} render={
        ({match}) => (
          <Authorize>
            <MasterLayout children={
              <ContestNotificationList contestId={match.params.contestId ? parseInt(match.params.contestId) : 0}/>
            }/>
          </Authorize>
        )}
      />

      <Route path={RoutingConstants.NO_PERMITTED}>
        <Authorize>
          <MasterLayout children={
            <NoPermitted/>
          }/>
        </Authorize>
      </Route>

      <Route path={RoutingConstants.UNSUBSCRIBE} render={
        ({match}) => (
          <UnauthorizedLayout children={
            <Unsubscribe subscriptionId={match.params.subscriptionId}/>
          }/>
        )}
      />

      <Route path="*">
        <NotFound/>
      </Route>
    </Switch>
  );
};

export default Routes;
