import React, { Component } from 'react';
import { Form } from "react-bootstrap-v5";
import FieldNameHelper from "../../../helpers/field-name-helper/FieldNameHelper";
import './Checkbox.scss';
import { ICheckboxProps } from "./ICheckboxProps";
import { ICheckboxState } from "./ICheckboxState";

class Checkbox extends Component<ICheckboxProps, ICheckboxState> {

  constructor(props: ICheckboxProps) {
    super(props);

    this.state = {};
  }

  render() {
    let {checked, id} = this.props;
    const checkboxFieldName = FieldNameHelper.getCheckboxName(id);

    return (
      <Form.Check type='checkbox' id={checkboxFieldName}
                  name={checkboxFieldName}
                  checked={checked}
                  onChange={this.onCheckboxChange.bind(this)}
      />
    );
  }

  onCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (this.props.onCheckboxChange) {
      this.props.onCheckboxChange(e.target.checked);
    }
  }
}

export default Checkbox;