import React, { Component } from 'react';
import { Col, Form, Row } from "react-bootstrap-v5";
import FormDescription from "../../../../../../components/common/form-description/FormDescription";
import ValidationMessages from "../../../../../../components/common/validation-messages/ValidationMessages";
import { VerticalSpaceSize } from "../../../../../../components/common/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../../../../components/common/vertical-space/VerticalSpace";
import FieldNameHelper from "../../../../../../helpers/field-name-helper/FieldNameHelper";
import StaffOnly from "../staff-only/StaffOnly";
import { ITextareaFieldProps } from "./ITextareaFieldProps";
import { ITextareaFieldState } from "./ITextareaFieldState";
import './TextareaField.scss';

class TextareaField extends Component<ITextareaFieldProps, ITextareaFieldState> {

  constructor(props: ITextareaFieldProps) {
    super(props);

    this.state = {};
  }

  render() {
    let {field, index, errors} = this.props;
    const textareaFieldName = FieldNameHelper.getTextareaFieldName(index);
    const stringValueFieldName = FieldNameHelper.getStringValueFieldName(index);

    return (
      <>
        <Row>
          <Col md={6} lg={5}>
            <Form.Label className='text-break'>{field.name}</Form.Label>
            <Form.Control name={textareaFieldName} as="textarea" type="text"
                          placeholder={`Please enter ${field.name}`}
                          value={field.value ? field.value : ''}
                          onChange={this.onTextareaChange.bind(this)}
            />
            <StaffOnly showOnCode={field.showOnCode}/>
            <FormDescription formText={field.description}/>
            <ValidationMessages fieldName={textareaFieldName} errors={errors}/>
            <ValidationMessages fieldName={stringValueFieldName} errors={errors}/>
          </Col>
        </Row>
        <VerticalSpace size={VerticalSpaceSize.small}/>
      </>
    );
  }

  onTextareaChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (this.props.onTextareaChange) {
      this.props.onTextareaChange(e.target.value);
    }
  }
}

export default TextareaField;