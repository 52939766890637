import moment from "moment";
import React, { Component } from 'react';
import { Col, Form, InputGroup, Row } from "react-bootstrap-v5";
import { Redirect } from "react-router-dom";
import { toast } from 'react-toastify';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import urljoin from "url-join";
import Api from "../../../api/Api";
import { ICheckVotingEndDateRequest } from "../../../api/api-interfaces/contest/ICheckVotingEndDateRequest";
import { ICustomFieldInfo } from "../../../api/api-interfaces/contest/ICustomFieldInfo";
import { ISaveContestRequest } from "../../../api/api-interfaces/contest/ISaveContestRequest";
import withVotingAppAuthorize from "../../../components/common/authorize/withVotingAppAuthorize";
import IconButton from "../../../components/common/buttons/icon-button/IconButton";
import ToggleButton from '../../../components/common/buttons/toggle-button/ToggleButton';
import DateTimePicker from "../../../components/common/date-time-picker/DateTimePicker";
import FormDescription from "../../../components/common/form-description/FormDescription";
import LoadingBar from "../../../components/common/loading-bar/LoadingBar";
import RequiredField from "../../../components/common/required-field/RequiredField";
import ValidationMessages from "../../../components/common/validation-messages/ValidationMessages";
import ValidationSummary from "../../../components/common/validation-summary/ValidationSummary";
import { VerticalSpaceSize } from "../../../components/common/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../components/common/vertical-space/VerticalSpace";
import {
  CHECK_ERROR_MESSAGE,
  CONTEST_SORTING,
  CUSTOM_FIELD_CODES,
  DATE_TIME_ISO_FORMAT,
  NOTIFICATION_NEEDED,
  VOTING_RULE_CODES,
} from "../../../constants/Constants";
import { scrollIntoViewHelper } from "../../../helpers/scroll-into-view-helper/scrollIntoViewHelper";
import ValidationErrors from "../../../helpers/validation-helper/ValidationErrors";
import Validations from "../../../helpers/validation-helper/Validations";
import RoutingConstants from "../../../routes/RoutingConstants";
import './AddOrEditContest.scss';
import ChangeVotingEndTimeNotificationModal
  from "./change-voting-end-time-notification-modal/ChangeVotingEndTimeNotificationModal";
import CustomFieldsTable from "./custom-fields/CustomFieldsTable";
import { IAddOrEditContestProps } from "./IAddOrEditContestProps";
import { IAddOrEditContestState } from "./IAddOrEditContestState";
import { sunEditorOptions } from "./SunEditorOptions";

const DEFAULT: string = "DEFAULT";

class AddOrEditContest extends Component<IAddOrEditContestProps, IAddOrEditContestState> {

  constructor(props: IAddOrEditContestProps) {
    super(props);

    this.state = {
      contestId: null,
      pageToRedirect: 1,
      sorting: CONTEST_SORTING.Name,
      publicUiRootUrl: '',
      name: '',
      description: '',
      isActive: true,

      hideVoteCount: false,
      showTotalVoteCount: false,
      showEntryDescriptionOnTiles: false,
      totalNumberOfWinners: 1,

      votingRuleCode: DEFAULT,
      maxEntriesPerVote: 1,

      votingStartTime: null,
      votingEndTime: null,
      entrySubmitStartTime: null,
      entrySubmitEndTime: null,

      rulesAndPrizesHtml: "",
      customFields: [],

      votingRules: [],
      customFieldTypes: [],
      customFieldShowOnValues: [],

      isVotingEndTimeDialogOpen: false,
      notificationNeeded: NOTIFICATION_NEEDED.None,

      shouldShowContestIsCopiedToastMessage: "no",

      redirect: null,
      excludeKeys: ['Name', 'Description', 'VotingRuleCode', 'MaxEntriesPerVote', 'VotingStartTime', 'VotingStartTime',
        'VotingEndTime', 'EntrySubmitStartTime', 'EntrySubmitEndTime', 'RulesAndPrizesHtml'],
      validationErrors: null,
      isLoading: true,
      redirectUrl: null
    };
  }

  render() {
    if (this.state.redirect !== null) {
      return <Redirect push to={this.state.redirect}/>;
    }

    return (
      <>
        {
          this.state.validationErrors &&
          <Row>
            <Col md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}}>
              <ValidationSummary errors={this.state.validationErrors} excludeKeys={this.state.excludeKeys}/>
            </Col>
          </Row>
        }

        {
          this.state.isLoading ? <LoadingBar/> :
            <>
              <ChangeVotingEndTimeNotificationModal
                showDialog={this.state.isVotingEndTimeDialogOpen}
                notificationNeeded={this.state.notificationNeeded}
                onConfirm={this.onConfirmNotifyClick.bind(this)}
                onCancel={this.onCancelNotifyClick.bind(this)}
              />

              <h1 className={"headline"}>{this.state.contestId ? "Edit contest" : "Create contest"}</h1>
              <VerticalSpace size={VerticalSpaceSize.small}/>

              <Form>
                <IconButton onClick={this.onSaveContest.bind(this)} title="Save" variant={"outline-primary"}
                            styles={{marginRight: "1em"}}/>

                <IconButton onClick={this.onCancel.bind(this)} title="Cancel" variant={"outline-secondary"}/>

                <VerticalSpace size={VerticalSpaceSize.normal}/>

                {
                  this.state.contestId &&
                  <>
                    <Row>
                      <Col md={12} lg={10} xl={8}>
                        <Form.Label>Link to contest on public site</Form.Label>
                        <InputGroup>
                          <Form.Control value={this.getUrl()} disabled/>
                          <IconButton title="Copy to clipboard" variant='outline-primary'
                                      onClick={this.copyCodeToClipboard.bind(this)}/>
                        </InputGroup>
                        <FormDescription
                          formText='Contest URL that public visitors will be able to go to in order to see entries and vote'/>
                      </Col>
                    </Row>
                    <VerticalSpace size={VerticalSpaceSize.small}/>
                  </>
                }

                <Row>
                  <Col md={6} lg={5} xl={4}>
                    <Form.Label>Name<RequiredField/></Form.Label>
                    <Form.Control name="Name" type="text" placeholder="Please enter name"
                                  value={this.state.name}
                                  onChange={this.onNameChange.bind(this)}/>
                    <FormDescription formText='The name is how it appears on your site'/>
                    <ValidationMessages fieldName="Name" errors={this.state.validationErrors ?? {}}/>
                  </Col>

                  <Col md={6} lg={5} xl={4}>
                    <Form.Label>Description</Form.Label>
                    <Form.Control name="Description" as="textarea" type="text" placeholder="Please enter description"
                                  value={this.state.description}
                                  onChange={this.onDescriptionChange.bind(this)}
                    />
                    <FormDescription formText='Contest description'/>
                    <ValidationMessages fieldName="Description" errors={this.state.validationErrors ?? {}}/>
                  </Col>
                </Row>
                <VerticalSpace size={VerticalSpaceSize.small}/>

                <Row className='align-items-center'>
                  <Col xs={6} md={4} lg={3} xl={3}>
                    <strong className='text-break'>Hide vote count</strong>
                  </Col>
                  <Col>
                    <ToggleButton selected={this.state.hideVoteCount}
                                  toggleSelected={(isSelected) => this.onVoteCountHideToggle(isSelected)}
                    />
                  </Col>
                  <FormDescription formText='Hide vote count in entry listing and description page'/>
                </Row>
                <VerticalSpace size={VerticalSpaceSize.small}/>

                <Row className='align-items-center'>
                  <Col xs={6} md={4} lg={3} xl={3}>
                    <strong className='text-break'>Show total vote count</strong>
                  </Col>
                  <Col>
                    <ToggleButton selected={this.state.showTotalVoteCount}
                                  toggleSelected={(isSelected) => this.onShowTotalVoteCountToggle(isSelected)}
                    />
                  </Col>
                  <FormDescription formText='Display total number of votes for entire contest below timer'/>
                </Row>
                <VerticalSpace size={VerticalSpaceSize.small}/>

                <Row className='align-items-center'>
                  <Col xs={6} md={4} lg={3} xl={3}>
                    <strong className='text-break'>Show entry description on tiles</strong>
                  </Col>
                  <Col>
                    <ToggleButton selected={this.state.showEntryDescriptionOnTiles}
                                  toggleSelected={(isSelected) => this.onShowEntryDescriptionToggle(isSelected)}
                    />
                  </Col>
                  <FormDescription formText='Display entry descriptions in tiles list view'/>
                </Row>
                <VerticalSpace size={VerticalSpaceSize.small}/>

                <Row>
                  <Col md={6} lg={5} xl={4}>
                    <Form.Label>Maximum number of winners<RequiredField/></Form.Label>
                    <Form.Control id='TotalNumberOfWinners' name='TotalNumberOfWinners' type="number"
                                  className='form-control'
                                  placeholder='Please enter maximum number of winners'
                                  value={this.state.totalNumberOfWinners}
                                  onChange={this.onChangeTotalNumberOfWinners.bind(this)}>
                    </Form.Control>
                    <FormDescription formText='Number of entries that can become winners in this contest'/>
                    <ValidationMessages fieldName="TotalNumberOfWinners" errors={this.state.validationErrors ?? {}}/>
                  </Col>
                </Row>
                <VerticalSpace size={VerticalSpaceSize.small}/>

                <Row>
                  <Col md={12} lg={10} xl={8}>
                    <h5 className='mb-0 pb-0' style={{marginTop: '2rem'}}>Voting rules</h5>
                    <hr className='mt-0 pt-0'/>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} lg={5} xl={4}>
                    <Form.Label>Voting rules<RequiredField/></Form.Label>
                    {
                      this.state.votingRules.map((votingRule, i) => {
                        return (
                          <div key={votingRule.code}>
                            <Form.Check id={votingRule.code}
                                        name={i === 3 ? 'VotingRuleCode' : `VotingRuleCode_${i}`}
                                        type='radio'
                                        value={this.state.votingRuleCode}
                                        checked={votingRule.code === this.state.votingRuleCode}
                                        onChange={() => this.onChangeVotingRules(votingRule.code)}
                                        label={<b>{votingRule.name}</b>}
                            />
                            {
                              this.getVotingRuleCodeDescription(votingRule.code)
                            }
                          </div>
                        );
                      })
                    }
                    <FormDescription formText='Voting rules for contest'/>
                    <ValidationMessages fieldName="VotingRuleCode" errors={this.state.validationErrors ?? {}}/>
                  </Col>

                  <Col md={6} lg={5} xl={4}>
                    <Form.Label>Maximum votes<RequiredField/></Form.Label>
                    <Form.Control id='MaxEntriesPerVote' name='MaxEntriesPerVote' type="number" className='form-control'
                                  placeholder='Please enter maximum entries'
                                  value={this.state.maxEntriesPerVote}
                                  onChange={this.onChangeMaxEntriesPerVote.bind(this)}>
                    </Form.Control>
                    <FormDescription formText='Number of entries user can select within one voting session'/>
                    <ValidationMessages fieldName="MaxEntriesPerVote" errors={this.state.validationErrors ?? {}}/>
                  </Col>
                </Row>
                <VerticalSpace size={VerticalSpaceSize.small}/>
                
                <Row>
                  <Col md={12} lg={10} xl={8}>
                    <h5 className='mb-0 pb-0' style={{marginTop: '2rem'}}>Entry submit time</h5>
                    <hr className='mt-0 pt-0'/>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={5} xl={4}>
                    <Form.Group controlId="entrySubmitStartTime">
                      <Form.Label>Entry submit start time</Form.Label><br/>
                      <DateTimePicker name="EntrySubmitStartTime"
                                      datePickerId={"entrySubmitStartTime"}
                                      selected={this.state.entrySubmitStartTime}
                                      onChange={(date: Date) => this.setEntrySubmitStartTime(date)}
                      />
                      <FormDescription
                        formText='Date and time when submitters will be allowed to start submitting entries'/>
                      <ValidationMessages fieldName="EntrySubmitStartTime" errors={this.state.validationErrors ?? {}}/>
                    </Form.Group>
                  </Col>

                  <Col md={6} lg={5} xl={4}>
                    <Form.Group controlId="entrySubmitEndTime">
                      <Form.Label>Entry submit end time</Form.Label><br/>
                      <DateTimePicker name="EntrySubmitEndTime"
                                      datePickerId={"entrySubmitEndTime"}
                                      selected={this.state.entrySubmitEndTime}
                                      onChange={(date: Date) => this.setEntrySubmitEndTime(date)}
                      />
                      <FormDescription formText='Date and time when entries submission should stop'/>
                      <ValidationMessages fieldName="EntrySubmitEndTime" errors={this.state.validationErrors ?? {}}/>
                    </Form.Group>
                  </Col>
                </Row>
                <VerticalSpace size={VerticalSpaceSize.small}/>

                <Row>
                  <Col md={12} lg={10} xl={8}>
                    <h5 className='mb-0 pb-0' style={{marginTop: '2rem'}}>Voting time</h5>
                    <hr className='mt-0 pt-0'/>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} lg={5} xl={4}>
                    <Form.Group controlId="votingStartTime">
                      <Form.Label>Voting start time</Form.Label><br/>
                      <DateTimePicker name="VotingStartTime"
                                      datePickerId={"votingStartTime"}
                                      selected={this.state.votingStartTime}
                                      onChange={(date: Date) => this.setVotingStartTime(date)}
                      />
                      <FormDescription formText='Date and time for voting to start'/>
                      <ValidationMessages fieldName="VotingStartTime" errors={this.state.validationErrors ?? {}}/>
                    </Form.Group>
                  </Col>

                  <Col md={6} lg={5} xl={4}>
                    <Form.Group controlId="votingEndTime">
                      <Form.Label>Voting end time</Form.Label><br/>
                      <DateTimePicker name="VotingEndTime"
                                      datePickerId={"votingEndTime"}
                                      selected={this.state.votingEndTime}
                                      onChange={(date: Date) => this.setVotingEndTime(date)}
                      />
                      <FormDescription formText='Date and time when voting ends'/>
                      <ValidationMessages fieldName="VotingEndTime" errors={this.state.validationErrors ?? {}}/>
                    </Form.Group>
                  </Col>
                </Row>
                <VerticalSpace size={VerticalSpaceSize.large}/>

                <Row>
                  <Col lg={10} xl={8} style={{zIndex: 0}}>
                    <Form.Group controlId="sunEditor">
                      <Form.Label>Rules & Prizes</Form.Label><br/>
                      <SunEditor lang="en"
                                 setDefaultStyle="font-family: Arial; font-size: 13px;"
                                 defaultValue={this.state.rulesAndPrizesHtml}
                                 onChange={this.onChangeEditor.bind(this)}
                                 setOptions={sunEditorOptions}
                      />
                    </Form.Group>
                    <FormDescription formText='Description of rules and prizes'/>
                    <ValidationMessages fieldName="RulesAndPrizesHtml" errors={this.state.validationErrors ?? {}}/>
                  </Col>
                </Row>
                <VerticalSpace size={VerticalSpaceSize.small}/>

                <CustomFieldsTable customFields={this.state.customFields}
                                   customFieldTypes={this.state.customFieldTypes}
                                   customFieldShowOnValues={this.state.customFieldShowOnValues}
                                   onAddCustomFieldClick={(customField: ICustomFieldInfo) => this.onAddCustomField(customField)}
                                   onEditCustomFieldClick={(editedCustomField: ICustomFieldInfo, indexToEdit: number) => this.onEditCustomField(editedCustomField, indexToEdit)}
                                   onDeleteCustomFieldClick={(indexToDelete: number) => this.onDeleteCustomField(indexToDelete)}
                                   onUpCustomFieldClick={(indexToUp: number) => this.onUpCustomField(indexToUp)}
                                   onDownCustomFieldClick={(indexToDown: number) => this.onDownCustomField(indexToDown)}
                                   validationErrors={this.state.validationErrors}
                />
                <VerticalSpace size={VerticalSpaceSize.normal}/>

                <IconButton onClick={this.onSaveContest.bind(this)} title="Save" variant={"outline-primary"}
                            styles={{marginRight: "1em"}}/>

                <IconButton onClick={this.onCancel.bind(this)} title="Cancel" variant={"outline-secondary"}/>
              </Form>
            </>
        }
      </>
    );
  }

  getVotingRuleCodeDescription(votingRuleCode: string) {
    switch (votingRuleCode) {
      case VOTING_RULE_CODES.OneTime:
        return (
          <ul className="form-text votingRulesList">
            <li>
              User is allowed to give a voice for one or more entries. Maximum number of votes that user can give is set
              in field "Maximum votes".
            </li>
            <li>
              User can select any entry. It is allowed to vote for the same entries multiple times.
            </li>
            <li>
              Once user gives all votes, voting will not be allowed for that user till end of contest.
            </li>
          </ul>
        )
      case VOTING_RULE_CODES.OncePerDay:
        return (
          <ul className="form-text votingRulesList">
            <li>
              User is allowed to give votes for one or more entries each day. Maximal number of votes that user can give
              each day is set in field "Maximum votes".
            </li>
            <li>
              User can select any entry. If maximum allowed number of votes is higher than 1, then user is also allowed
              to give multiple votes for the same entry.
            </li>
            <li>
              Once user uses all votes, voting will not be allowed for the user till end of a day. User will be able to
              vote again next day.
            </li>
          </ul>
        )
      case VOTING_RULE_CODES.OncePerDayPerEntry:
        return (
          <ul className="form-text votingRulesList">
            <li>
              User is allowed to give votes for one or more different entries each day. Every entry can be voted no more
              than once. Maximal number of votes that user can give each day is set in field "Maximum votes".
            </li>
            <li>
              If user has given vote for one entry, then user will not be allowed to vote for the same entry till end of
              the day.
            </li>
            <li>
              Once user uses all votes, voting will not be allowed for the user till end of the day. User will be
              allowed to vote next day.
            </li>
          </ul>
        )
      case VOTING_RULE_CODES.OncePerEntry:
        return (
          <ul className="form-text votingRulesList">
            <li>
              User is allowed to vote random number of times during entire contest duration. But user is allowed to give
              limited amount of votes for each entry. Maximum number of votes that user can give for an entry is
              specified in field "Maximum votes".
            </li>
            <li>
              If user has given maximal allowed number of votes for some entry, then user will not be able to vote for
              that entry till end of contest.
            </li>
          </ul>
        )
      default:
        break;
    }
  }

  private getUrl() {
    let url = (new URL(`/#ki-va/contest/${this.state.contestId}/1`, this.state.publicUiRootUrl)).href;

    return url;
  }

  async componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const page = params.get('page');
    const pageToRedirect = !page ? 1 : parseInt(page);

    const sortingParam = params.get('sorting');
    const sorting = sortingParam ? sortingParam : this.state.sorting;

    const url = params.get('redirectUrl');
    const redirectUrl = url ? url : null;
    let contestId = this.props.contestId;

    const shouldShowContestIsCopiedToastMessageParam = params.get('shouldShowContestIsCopiedToastMessage');
    const shouldShowContestIsCopiedToastMessage =
      shouldShowContestIsCopiedToastMessageParam
        ? shouldShowContestIsCopiedToastMessageParam
        : "no";

    this.setState({
      contestId: contestId,
      pageToRedirect: pageToRedirect,
      sorting: sorting,
      redirectUrl: redirectUrl,
      shouldShowContestIsCopiedToastMessage: shouldShowContestIsCopiedToastMessage
    });

    if (contestId) {
      await this.getContestDetails(contestId);
    } else {
      await this.getContestOptions();
    }
  }

  private async getContestDetails(contestId: number) {
    try {
      let response = await Api.getContestEditDetails(contestId);

      let state = {...this.state};
      state.contestId = response.contestId;
      state.publicUiRootUrl = response.publicUiRootUrl;
      state.name = response.name;
      state.description = response.description;
      state.isActive = response.isActive;
      state.hideVoteCount = response.hideVoteCount;
      state.showTotalVoteCount = response.showTotalVoteCount;
      state.showEntryDescriptionOnTiles = response.showEntryDescriptionOnTiles;
      state.totalNumberOfWinners = response.totalNumberOfWinners;
      state.votingRuleCode = response.votingRuleCode;
      state.maxEntriesPerVote = response.maxEntriesPerVote;
      state.votingStartTime = response.votingStartTime ? moment(response.votingStartTime).toDate() : null;
      state.votingEndTime = response.votingEndTime ? moment(response.votingEndTime).toDate() : null;
      state.entrySubmitStartTime = response.entrySubmitStartTime ? moment(response.entrySubmitStartTime).toDate() : null;
      state.entrySubmitEndTime = response.entrySubmitEndTime ? moment(response.entrySubmitEndTime).toDate() : null;
      state.rulesAndPrizesHtml = response.rulesAndPrizesHtml;
      state.customFields = response.customFields ?? [];

      state.votingRules = response.votingRules;
      state.customFieldTypes = response.customFieldTypes;
      state.customFieldShowOnValues = response.customFieldShowOnValues;

      let excludeKeys = this.addFieldsToExcludeKeys(response.customFields ?? []);
      state.excludeKeys = [...state.excludeKeys, ...excludeKeys];

      state.isLoading = false;
      this.setState(state);

      if (this.state.shouldShowContestIsCopiedToastMessage === "yes") {
        let message = "Contest is copied. Please do not forget to set voting dates and entry submission dates.";
        AddOrEditContest.showSuccessToastMessage(message);
      }
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get contest edit details from the server', err)
      );
    }
  }

  private async getContestOptions() {
    try {
      let response = await Api.getContestOptions();

      this.setState({
        votingRules: response.votingRules,
        customFieldTypes: response.customFieldTypes,
        customFieldShowOnValues: response.customFieldShowOnValues,
        isLoading: false
      });
    } catch (err) {
      this.setValidationErrors(
        Validations.buildApiCommunicationErrors('Can\'t get contest options from the server', err)
      );
    }
  }

  public async copyCodeToClipboard() {
    let text = this.getUrl();
    let message = 'URL was copied to a clipboard';

    if ('clipboard' in navigator) {
      AddOrEditContest.showSuccessToastMessage(message);
      return await navigator.clipboard.writeText(text ?? '');
    } else {
      AddOrEditContest.showSuccessToastMessage(message);
      return document.execCommand('copy');
    }
  }

  private onNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    let errors = Validations.deleteErrors(this.state.validationErrors ?? {}, 'Name');
    this.setState({name: e.target.value, validationErrors: errors});
  }

  private onDescriptionChange(e: React.ChangeEvent<HTMLInputElement>) {
    let errors = Validations.deleteErrors(this.state.validationErrors ?? {}, 'Description');
    this.setState({description: e.target.value, validationErrors: errors});
  }

  private onChangeTotalNumberOfWinners(event: React.ChangeEvent<HTMLInputElement>) {
    let state = {...this.state};
    state.totalNumberOfWinners = parseInt(event.target.value);
    state.validationErrors = Validations.deleteErrors(this.state.validationErrors ?? {}, 'TotalNumberOfWinners');
    this.setState(state);
  }

  private onChangeVotingRules(votingRuleCode: string) {
    let state = {...this.state};
    state.votingRuleCode = votingRuleCode;
    state.validationErrors = Validations.deleteErrors(this.state.validationErrors ?? {}, 'VotingRuleCode');
    this.setState(state);
  }

  private onChangeMaxEntriesPerVote(event: React.ChangeEvent<HTMLInputElement>) {
    let state = {...this.state};
    state.maxEntriesPerVote = parseInt(event.target.value);
    state.validationErrors = Validations.deleteErrors(this.state.validationErrors ?? {}, 'MaxEntriesPerVote');
    this.setState(state);
  }

  private setVotingStartTime(date: Date) {
    let state = {...this.state};
    state.votingStartTime = date;
    state.validationErrors = Validations.deleteErrors(this.state.validationErrors ?? {}, 'VotingStartTime');
    this.setState(state);
  }

  private setVotingEndTime(date: Date) {
    let state = {...this.state};
    state.votingEndTime = date;
    state.validationErrors = Validations.deleteErrors(this.state.validationErrors ?? {}, 'VotingEndTime');
    this.setState(state);
  }

  private setEntrySubmitStartTime(date: Date) {
    let state = {...this.state};
    state.entrySubmitStartTime = date;
    state.validationErrors = Validations.deleteErrors(this.state.validationErrors ?? {}, 'EntrySubmitStartTime');
    this.setState(state);
  }

  private setEntrySubmitEndTime(date: Date) {
    let state = {...this.state};
    state.entrySubmitEndTime = date;
    state.validationErrors = Validations.deleteErrors(this.state.validationErrors ?? {}, 'EntrySubmitEndTime');
    this.setState(state);
  }

  private onChangeEditor(editor: any) {
    let state = {...this.state};
    state.rulesAndPrizesHtml = editor;
    state.validationErrors = Validations.deleteErrors(this.state.validationErrors ?? {}, 'RulesAndPrizesHtml');
    this.setState(state);
  }

  private async onSaveContest() {
    let errors = this.validateForm();
    if (Object.keys(errors).length > 0) {
      this.setState({validationErrors: errors});
      AddOrEditContest.showErrorToastMessage();
      scrollIntoViewHelper(errors);
      return;
    }

    let contestId = this.state.contestId;
    if (contestId) {
      await this.checkVotingEndDate();
    } else {
      await this.saveContest();
    }
  }

  private async checkVotingEndDate() {
    this.setState({isLoading: true});

    let request: ICheckVotingEndDateRequest = {
      contestId: this.state.contestId ?? 0,
      newVotingEndTime: this.getTimeOrNull(this.state.votingEndTime),
    };

    try {
      let response = await Api.checkVotingEndDate(request);

      if (response.notificationNeeded === NOTIFICATION_NEEDED.None) {
        await this.saveContest();
      }

      if (response.notificationNeeded === NOTIFICATION_NEEDED.FromFutureToPast) {
        let state = {...this.state};
        state.isLoading = false;
        state.isVotingEndTimeDialogOpen = true;
        state.notificationNeeded = response.notificationNeeded;
        this.setState(state);
      }

      if (response.notificationNeeded === NOTIFICATION_NEEDED.FromPastToFuture) {
        let state = {...this.state};
        state.isLoading = false;
        state.isVotingEndTimeDialogOpen = true;
        state.notificationNeeded = response.notificationNeeded;
        this.setState(state);
      }
    } catch (err) {
      this.setValidationErrors(Validations.buildApiCommunicationErrors('Can\'t check voting end date', err));
    }
  }

  private async saveContest() {
    this.setState({isLoading: true});

    let rulesAndPrizesHtml =
      (this.state.rulesAndPrizesHtml === "<p><br></p>") || (this.state.rulesAndPrizesHtml === "")
        ? null
        : this.state.rulesAndPrizesHtml;

    let request: ISaveContestRequest = {
      contestId: this.state.contestId ? this.state.contestId : null,
      name: this.state.name,
      description: this.state.description,
      hideVoteCount: this.state.hideVoteCount,
      showTotalVoteCount: this.state.showTotalVoteCount,
      showEntryDescriptionOnTiles: this.state.showEntryDescriptionOnTiles,
      totalNumberOfWinners: this.state.totalNumberOfWinners,
      votingRuleCode: this.state.votingRuleCode,
      maxEntriesPerVote: this.state.maxEntriesPerVote,
      votingStartTime: this.getTimeOrNull(this.state.votingStartTime),
      votingEndTime: this.getTimeOrNull(this.state.votingEndTime),
      entrySubmitStartTime: this.getTimeOrNull(this.state.entrySubmitStartTime),
      entrySubmitEndTime: this.getTimeOrNull(this.state.entrySubmitEndTime),
      rulesAndPrizesHtml: rulesAndPrizesHtml,
      customFields: this.state.customFields.length > 0 ? this.state.customFields : null
    };

    try {
      let response = await Api.saveContest(request);

      let state = {...this.state};
      state.redirect = this.getRedirect(response.contestId);
      this.setState(state);
    } catch (err) {
      this.setValidationErrors(Validations.buildApiCommunicationErrors('Can\'t create or update contest', err));
    }
  }

  private getRedirect(contestId: number) {
    if (this.state.redirectUrl) {
      return this.state.redirectUrl;
    } else {
      return this.state.contestId
        ? RoutingConstants.buildContestUrl(this.state.pageToRedirect, this.state.contestId, this.state.sorting)
        : RoutingConstants.buildContestUrl(this.state.pageToRedirect, contestId, this.state.sorting);
    }
  }

  private validateForm(): ValidationErrors {
    let errors: ValidationErrors = {};

    if (!this.state.name || this.state.name.trim().length < 1) {
      errors = Validations.setErrors({...errors}, 'Name', ['Name is required.']);
    }

    if (!this.state.totalNumberOfWinners || this.state.totalNumberOfWinners < 1) {
      errors = Validations.setErrors({...errors}, 'TotalNumberOfWinners', ['Maximum number of winner should be greater than zero.']);
    }

    if (this.state.votingRuleCode === DEFAULT) {
      errors = Validations.setErrors({...errors}, 'VotingRuleCode', ['Please select voting rules.']);
    }

    if (!this.state.maxEntriesPerVote || this.state.maxEntriesPerVote < 1) {
      errors = Validations.setErrors({...errors}, 'MaxEntriesPerVote', ['Max entries per vote should be greater than zero.']);
    }

    if (this.state.votingStartTime && this.state.votingEndTime) {
      if (this.state.votingEndTime.valueOf() <= this.state.votingStartTime.valueOf()) {
        errors = Validations.setErrors({...errors}, 'VotingEndTime', ['Voting end time should be after start time.']);
      }
    }

    if (this.state.entrySubmitStartTime && this.state.entrySubmitEndTime) {
      if (this.state.entrySubmitEndTime.valueOf() <= this.state.entrySubmitStartTime.valueOf()) {
        errors = Validations.setErrors({...errors}, 'EntrySubmitEndTime', ['Entry submit end time should be after start time.']);
      }
    }

    return errors;
  }

  private async onConfirmNotifyClick() {
    let state = {...this.state};
    state.isVotingEndTimeDialogOpen = false;
    state.notificationNeeded = NOTIFICATION_NEEDED.None;
    this.setState(state);

    await this.saveContest();
  }

  private onCancelNotifyClick() {
    let state = {...this.state};
    state.isVotingEndTimeDialogOpen = false;
    state.notificationNeeded = NOTIFICATION_NEEDED.None;
    this.setState(state);
  }

  private onCancel() {
    let state = {...this.state};
    state.redirect = this.getRedirect(0);
    this.setState(state);
  }

  private setValidationErrors(validationErrors: ValidationErrors) {
    let state = {...this.state};
    state.validationErrors = validationErrors;
    state.isLoading = false;
    this.setState(state);
  }

  private onVoteCountHideToggle(isSelected: boolean) {
    let state = {...this.state};
    state.hideVoteCount = !isSelected;
    this.setState(state);
  }

  private onShowTotalVoteCountToggle(isToggled: boolean) {
    let state = {...this.state};
    state.showTotalVoteCount = !isToggled;
    this.setState(state);
  }

  private onShowEntryDescriptionToggle(isToggled: boolean) {
    let state = {...this.state};
    state.showEntryDescriptionOnTiles = !isToggled;
    this.setState(state);
  }

  private onAddCustomField(customField: ICustomFieldInfo) {
    let state = {...this.state};
    customField.order = state.customFields.length + customField.order;
    this.addExcludedKeys(state, customField);

    state.customFields.push(customField);
    this.setState(state);
  }

  private addExcludedKeys(state: IAddOrEditContestState, customField: ICustomFieldInfo) {
    const customFieldPrefix: string = `CustomFields__${state.customFields.length}`;

    if (!state.excludeKeys.includes(customFieldPrefix)) {
      state.excludeKeys.push(`${customFieldPrefix}`);
    }

    if (!state.excludeKeys.includes(`${customFieldPrefix}.Name`)) {
      state.excludeKeys.push(`${customFieldPrefix}.Name`);
    }

    if (!state.excludeKeys.includes(`${customFieldPrefix}.TypeCode`)) {
      state.excludeKeys.push(`${customFieldPrefix}.TypeCode`);
    }

    if (!state.excludeKeys.includes(`${customFieldPrefix}.ShowOnCode`)) {
      state.excludeKeys.push(`${customFieldPrefix}.ShowOnCode`);
    }

    if (customField.typeCode === CUSTOM_FIELD_CODES.RadioButton || CUSTOM_FIELD_CODES.DropDownList) {
      customField.options?.forEach((o, j) => {
        if (!state.excludeKeys.includes(`${customFieldPrefix}.Options___${j}`)) {
          state.excludeKeys.push(`${customFieldPrefix}.Options___${j}`);
        }
      });
    }
  }

  private onEditCustomField(editedCustomField: ICustomFieldInfo, indexToEdit: number) {
    let customField = this.state.customFields[indexToEdit];
    this.deleteExcludedKeys(indexToEdit, customField);

    let customFields = [...this.state.customFields];
    customFields[indexToEdit] = {
      ...customFields[indexToEdit],
      name: editedCustomField.name,
      typeCode: editedCustomField.typeCode,
      optionValue: editedCustomField.optionValue,
      options: editedCustomField.options,
      description: editedCustomField.description,
      showOnCode: editedCustomField.showOnCode,
      isRequired: editedCustomField.isRequired
    };

    this.setState({customFields: customFields});
  }

  private deleteExcludedKeys(index: number, customField: ICustomFieldInfo) {
    const customFieldPrefix: string = `CustomFields__${index}`;
    let state = {...this.state};

    if (state.excludeKeys.includes(customFieldPrefix)) {
      let index = state.excludeKeys.indexOf(customFieldPrefix);
      state.excludeKeys.splice(index, 1);
    }

    if (state.excludeKeys.includes(`${customFieldPrefix}.Name`)) {
      let index = state.excludeKeys.indexOf(`${customFieldPrefix}.Name`);
      state.excludeKeys.splice(index, 1);
    }

    if (state.excludeKeys.includes(`${customFieldPrefix}.TypeCode`)) {
      let index = state.excludeKeys.indexOf(`${customFieldPrefix}.TypeCode`);
      state.excludeKeys.splice(index, 1);
    }

    if (state.excludeKeys.includes(`${customFieldPrefix}.ShowOnCode`)) {
      let index = state.excludeKeys.indexOf(`${customFieldPrefix}.ShowOnCode`);
      state.excludeKeys.splice(index, 1);
    }

    if (customField.typeCode === CUSTOM_FIELD_CODES.RadioButton || CUSTOM_FIELD_CODES.DropDownList) {
      customField.options?.forEach((o, j) => {
        if (state.excludeKeys.includes(`${customFieldPrefix}.Options___${j}`)) {
          let index = state.excludeKeys.indexOf(`${customFieldPrefix}.Options___${j}`);
          state.excludeKeys.splice(index, 1);
        }
      });
    }

    this.setState(state);
  }

  private onUpCustomField(indexToUp: number) {
    let customFields = [...this.state.customFields];

    if (indexToUp === 0) {
      return;
    }

    if (indexToUp > 0) {
      customFields.splice(indexToUp - 1, 0, customFields.splice(indexToUp, 1)[0]);
      customFields[indexToUp - 1] = {
        ...customFields[indexToUp - 1],
        order: indexToUp
      };
      customFields[indexToUp] = {
        ...customFields[indexToUp],
        order: indexToUp + 1
      };
    }

    this.setState({customFields: customFields});
  }

  private onDownCustomField(indexToDown: number) {
    let customFields = [...this.state.customFields];

    if (indexToDown + 1 === customFields.length) {
      return;
    }

    if (indexToDown !== customFields.length) {
      customFields.splice(indexToDown + 1, 0, customFields.splice(indexToDown, 1)[0]);
      customFields[indexToDown + 1] = {
        ...customFields[indexToDown + 1],
        order: indexToDown + 2
      };
      customFields[indexToDown] = {
        ...customFields[indexToDown],
        order: indexToDown + 1
      };
    }

    this.setState({customFields: customFields});
  }

  private onDeleteCustomField(indexToDelete: number) {
    let customField = this.state.customFields[indexToDelete];
    this.deleteExcludedKeys(indexToDelete, customField);

    let state = {...this.state};
    state.customFields.splice(indexToDelete, 1);
    this.setState(state);
  }

  private static showSuccessToastMessage(content: string) {
    toast.success(content);
  }

  private static showErrorToastMessage() {
    toast.error(CHECK_ERROR_MESSAGE);
  }

  addFieldsToExcludeKeys(customFields: ICustomFieldInfo[]) {
    if (customFields.length === 0) {
      return [];
    }

    let excludeKeys: string[] = [];

    customFields.forEach((field, i) => {
      switch (field.typeCode) {
        case CUSTOM_FIELD_CODES.Text:
        case CUSTOM_FIELD_CODES.TextArea:
        case CUSTOM_FIELD_CODES.Checkbox:
        case CUSTOM_FIELD_CODES.DatePicker:
          excludeKeys.push(`CustomFields__${i}`);
          break;
        case CUSTOM_FIELD_CODES.RadioButton:
        case CUSTOM_FIELD_CODES.DropDownList:
          if (field.options) {
            field.options.forEach((option, j) => {
              excludeKeys.push(`CustomFields__${i}.Options___${j}`);
            });
          }
          break;
        default:
          break;
      }
    });

    return excludeKeys;
  }

  public getTimeOrNull(dateTime: Date | null) {
    if (!dateTime) {
      return null;
    } else {
      return moment(dateTime).format(DATE_TIME_ISO_FORMAT);
    }
  }
}

export default withVotingAppAuthorize(AddOrEditContest);