import moment from "moment";
import React, { Component } from 'react';
import { Col, Form, Row } from "react-bootstrap-v5";
import DateTimePicker from "../../../../../../components/common/date-time-picker/DateTimePicker";
import FormDescription from "../../../../../../components/common/form-description/FormDescription";
import ValidationMessages from "../../../../../../components/common/validation-messages/ValidationMessages";
import { VerticalSpaceSize } from "../../../../../../components/common/vertical-space/IVerticalSpaceProps";
import VerticalSpace from "../../../../../../components/common/vertical-space/VerticalSpace";
import './DateTimePickerField.scss';
import FieldNameHelper from "../../../../../../helpers/field-name-helper/FieldNameHelper";
import StaffOnly from "../staff-only/StaffOnly";
import { IDateTimePickerFieldProps } from "./IDateTimePickerFieldProps";
import { IDateTimePickerFieldState } from "./IDateTimePickerFieldState";

class DateTimePickerField extends Component<IDateTimePickerFieldProps, IDateTimePickerFieldState> {

  constructor(props: IDateTimePickerFieldProps) {
    super(props);

    this.state = {};
  }

  render() {
    let {field, index, errors} = this.props;
    const datePickerFieldName = FieldNameHelper.getDatePickerFieldName(index);
    const dateTimeValueFieldName = FieldNameHelper.getDateTimeValueFieldName(index);

    return (
      <>
        <Row>
          <Col md={6} lg={5}>
            <Form.Group controlId={FieldNameHelper.getDatePickerFieldName(index)}>
              <Form.Label className='text-break'>{field.name}</Form.Label><br/>
              <DateTimePicker name={datePickerFieldName}
                              datePickerId={datePickerFieldName}
                              selected={field.value ? moment(field.value).toDate() : null}
                              onChange={(date: Date) => this.onDateClick(date)}
              />
              <StaffOnly showOnCode={field.showOnCode}/>
              <FormDescription formText={field.description}/>
              <ValidationMessages fieldName={datePickerFieldName} errors={errors}/>
              <ValidationMessages fieldName={dateTimeValueFieldName} errors={errors}/>
            </Form.Group>
          </Col>
        </Row>
        <VerticalSpace size={VerticalSpaceSize.small}/>
      </>
    );
  }

  private onDateClick(date: Date) {
    if (this.props.onDateClick !== undefined) {
      this.props.onDateClick(date);
    }
  }
}

export default DateTimePickerField;