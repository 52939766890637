import React, { FC, useState } from 'react';
import Authorize from "../common/authorize/Authorize";
import Content from './content/Content';
import Sidebar from './sidebar/Sidebar';

const MasterLayout: FC = ({children}) => {
  const [toggled, setToggled] = useState(false);

  const handleToggleSidebar = (value: boolean) => {
    setToggled(value);
  };

  return (
    <div className={`app ${toggled ? 'toggled' : ''}`}>
      <Authorize>
        <Sidebar toggled={toggled} handleToggleSidebar={handleToggleSidebar}/>
      </Authorize>

      <Content handleToggleSidebar={handleToggleSidebar} children={children}/>
    </div>
  );
}

export default MasterLayout;
