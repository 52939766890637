import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import Authorize from "../../common/authorize/Authorize";
import Footer from "../footer/Footer";
import { IContentProps } from "./IContentProps";

const Content: FC<IContentProps> = ({children, handleToggleSidebar}) => {
  return (
    <>
      <main>
        <Authorize>
          <div className="btn-toggle mb-3" onClick={() => handleToggleSidebar(true)}>
            <FontAwesomeIcon icon={faBars}/>
          </div>
        </Authorize>

        <header>
          {children}
        </header>

        <Authorize>
          <Footer/>
        </Authorize>
      </main>
    </>
  );
};

export default Content;
