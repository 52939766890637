import en from 'date-fns/locale/en-US';
import React, { Component } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DATE_PICKER_FORMAT, TIME_PICKER_FORMAT } from "../../../constants/Constants";
import CustomDatePickerInput from "./custom-date-picker-input/CustomDatePickerInput";
import { IDateTimePickerProps } from "./IDateTimePickerProps";
import './DateTimePicker.scss';

registerLocale('en', en);

export default class DateTimePicker extends Component<IDateTimePickerProps> {
  render() {
    return <DatePicker id={this.props.datePickerId}
                       name={this.props.name}
                       selected={this.props.selected}
                       className={"form-control myClassname"}
                       dateFormat={DATE_PICKER_FORMAT}
                       timeFormat={TIME_PICKER_FORMAT}
                       showTimeInput
                       timeInputLabel="Time:"
                       onChange={this.props.onChange}
                       locale={en}
                       showMonthDropdown
                       showYearDropdown
                       dropdownMode="select"
                       customInput={(<CustomDatePickerInput name={this.props.name}/>)}
                       isClearable={true}
    />;
  }
}